import { useState } from "react";
import { Link } from "react-router-dom";
import marker from "../assets/mapMarker.png";

interface Props {
  lat: number | undefined;
  lng: number | undefined;
  name: string | undefined;
  logo: string[] | undefined;
  status: string | undefined;
  busid: string | undefined;
}

const MARKER_WIDTH = 20;
const MARKER_HEIGHT = 30;

const VivreMarker = ({ lat, lng, name, logo, status, busid }: Props) => {
  const [open, setOpen] = useState(false);
  if (
    status === "Approuvé" ||
    status === "Gratuit" ||
    status?.includes("Payant")
  ) {
    return (
      <>
        <img
          alt=""
          src={marker}
          style={vivreMarkerStyle as any}
          onClick={() => setOpen(!open)}
        />
        {open && (
          <Link to={`/vivre/${busid}`}>
            <div className="vivreMarker__pin">
              <h4 className="vivreMarker__name">{name}</h4>
              <img
                alt=""
                src={logo ? logo[0] : ""}
                className="vivreMarker__logo"
              />
            </div>
          </Link>
        )}
      </>
    );
  } else {
    return <></>;
  }
};

const vivreMarkerStyle = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: "absolute",
  width: MARKER_WIDTH,
  height: MARKER_HEIGHT,
  left: -MARKER_WIDTH / 2,
  top: -MARKER_HEIGHT / 2,
};

export default VivreMarker;
