import { useState, useEffect, Dispatch, SetStateAction } from "react";
//Algolia
import algoliasearch from "algoliasearch";
//MUI
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

interface Props<T> {
  collection: string;
  searchString: string;
  setSearchString: Dispatch<SetStateAction<string>>;
  setQueryResults: Dispatch<SetStateAction<T[]>>;
  hitsPerPage?: number;
}

const AlgoliaSearchBar = <T extends unknown>({
  collection,
  searchString,
  setSearchString,
  setQueryResults,
  hitsPerPage,
}: Props<T>) => {
  //States
  const [queryOptions, setQueryOptions] = useState<string[]>([]);
  const [firstRender, setFirstRender] = useState(0);
  //Algolia
  const client = algoliasearch(
    "8C67M6HXJJ",
    "d48dd50834be210b57e54bfdaef8a293"
  );
  const index = client.initIndex(collection);

  useEffect(() => {
    const search = async () => {
      const results: any[] = (
        await index.search(searchString, { hitsPerPage: hitsPerPage })
      ).hits;
      if (results.length > 0) {
        const options: string[] = [];
        const queryResults: any[] = [];
        results.forEach((result) => {
          if (
            (result?.name !== undefined && result?.status === "Approuvé") ||
            result?.title !== undefined
          ) {
            options.push(result.name ?? result.title);
            queryResults.push(result);
          }
        });
        if (queryResults[0].title !== undefined) {
          const filteredResults = queryResults.filter((option) => {
            if (option.cancelled === false && option.finished === false)
              return option;
          });
          setQueryOptions(options);
          setQueryResults(filteredResults);
        } else {
          setQueryOptions(options);
          setQueryResults(queryResults);
        }
      }
    };
    if (firstRender === 2) search();
    else setFirstRender(firstRender + 1);
  }, [searchString, hitsPerPage]);

  return (
    <>
      <Autocomplete
        autoHighlight={true}
        freeSolo
        options={queryOptions}
        value={searchString}
        onChange={(e, value) => {
          if (value) setSearchString(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => {
              setSearchString(e.target.value);
            }}
            label="Rechercher"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
      />
    </>
  );
};

export default AlgoliaSearchBar;
