import React from "react";
import Slider from "react-slick";
// Interfaces
import { WebsiteImage } from "franco-interfaces";

interface Props {
  images: WebsiteImage[];
}

const CarouselImages: React.FC<Props> = ({ images }) => {

    const settings = {
        arrow: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
      };
  return (
      <Slider
        {...settings}
      >
        {images?.map((image) => (
          <div className="carousel__item">
            <a href={image.link} target="_blank">
              <img src={image && image.imageUrl ? image.imageUrl[0] : ""} />
            </a>
          </div>
        ))}
        </Slider>
  );
};

export default CarouselImages;
