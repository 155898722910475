import {
  useState,
  useImperativeHandle,
  Dispatch,
  SetStateAction,
  Ref,
  forwardRef,
} from "react";
// Firebase
import { auth, functions } from "../firebase/firebase";
//Interfaces
import { User } from "franco-interfaces";
// Hooks
import useDb from "../hooks/useDb";
import { useSnackbar } from "notistack";
// MUI
import TextField from "@material-ui/core/TextField";
// Custom Components
import Title from "./title";

interface Props {
  email: string;
  password: string;
  setEmail: Dispatch<SetStateAction<string>>;
  setPassword: Dispatch<SetStateAction<string>>;
  setUser: Dispatch<SetStateAction<User | undefined>>;
}

const Login = (
  { email, setEmail, password, setPassword, setUser }: Props,
  ref: Ref<any>
) => {
  // Hooks
  const { enqueueSnackbar } = useSnackbar();
  const userRequest = useDb<User>("Users", null);

  // States
  const [userExists, setUserExists] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [passwordValidated, setPassWordValidated] = useState(true);

  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  const handleSubmit = async (): Promise<boolean> => {
    if (!userExists) {
      const checkIfUserExists = functions.httpsCallable("checkIfUserExists");
      const user: any = await checkIfUserExists(email);

      if (user.data !== null) {
        setUser(user.data[0]);
      } else {
        setNewUser(true);
      }
      setUserExists(true);
      return false;
    } else if (newUser) {
      return createNewUser();
    } else {
      return signIn();
    }
  };

  const signIn = async (): Promise<boolean> => {
    try {
      if (email && password) {
        await auth.signInWithEmailAndPassword(email, password);
        enqueueSnackbar("Connexion établie", {
          variant: "success",
        });
        return true;
      }
    } catch (e: any) {
      if (e.code === "auth/user-not-found")
        enqueueSnackbar("Aucun compte associé avec ce courriel", {
          variant: "error",
        });
      else if (e.code === "auth/wrong-password")
        enqueueSnackbar("Combinaison de courriel et mot de passe incorrecte", {
          variant: "error",
        });
      else if (e.code === "auth/invalid-email")
        enqueueSnackbar("Courriel invalide", { variant: "error" });
      return false;
    }
    return false;
  };

  const createNewUser = async (): Promise<boolean> => {
    try {
      if (password === confirmedPassword) {
        const authResponse = await auth.createUserWithEmailAndPassword(
          email,
          password
        );
        const newUser = await userRequest.createDoc(
          {
            email: email,
            announcers: { supports: [] },
            favorites: {
              announcers: [],
              articles: [],
              podcastEpisodes: [],
              events: [],
            },
            subscriptions: {
              authors: [],
              shows: [],
              tags: [],
              newspaperSections: [],
              promotions: [],
            },
            roles: [],
            id: authResponse.user?.uid,
          },
          authResponse.user?.uid
        );
        enqueueSnackbar("Votre compte a été créé!", {
          variant: "success",
        });
        setUser(newUser);
        return true;
      } else {
        enqueueSnackbar("Les mots de passe ne correspondent pas", {
          variant: "error",
        });
        setPassWordValidated(false);
        return false;
      }
    } catch (e: any) {
      switch (e.code) {
        case "auth/email-already-in-use":
          enqueueSnackbar("Il y a déja un utilisateur avec cet identifiant", {
            variant: "error",
          });
          break;
        case "auth/weak-password":
          enqueueSnackbar(
            "votre mot de passe doit contenir au moins 6 charactère"
          ),
            {
              variant: "error",
            };
          break;
        default:
          enqueueSnackbar("une erreur est survenu, svp réesayez", {
            variant: "error",
          });
      }
      return false;
    }
  };

  return (
    <>
      <Title title="Courriel" />
      {window.location.pathname === "/annonceur" && 
        <div>
          <p>Le calendrier Frabio est le seul endroit qui regroupe toutes les activités en français en Alberta. Il est accessible sur le Web et sur l'application mobile Frabio. Vous organisez des événements en français? Faites-en la promotion ici, c'est tout simple :</p>
          <br/>
          <p>
            1. Créer un compte <br/>
            2. Procéder au paiement <br/>
            3. Ajouter ses événements dans le calendrier à l'aide du formulaire de mise en ligne
          </p>
          <br/>
          <p>Pour toutes questions, écrivez à <a href="mailto:appli@lefranco.ab.ca">appli@lefranco.ab.ca</a></p>
          <br/>
          <p>Combien coûte l'affichage dans le Frabio:</p>
          <p>
            &bull; 1 événement  - 79$ <br/>
            &bull; 4 événements - 139$ <br/>
            &bull; 12 événements - 179$ <br/>
            &bull; 52 événements - 229$
          </p>
          <br/>
        </div>
      }
      {window.location.pathname === "/formulaire" && 
        <div>
          <p>L'annuaire des services en français de Frabio est le seul outil qui permet de mettre en relation des clients et des entreprises francophones en Alberta. L'annuaire géolocalisé est accessible à tous gratuitement sur le Web et sur l'application mobile Frabio. Vous offrez des services en français en Alberta? Faites-en la promotion ici, c'est tout simple :</p>
          <br/>
          <p>
            1. Créer un compte avec les informations de votre entreprise <br/>
            2. Procéder au paiement<br/>
          </p>
          <br/>
          <p>Pour toutes questions, écrivez à <a href="mailto:appli@lefranco.ab.ca">appli@lefranco.ab.ca</a></p>
          <br/>
        </div>
      }
      <TextField
        id="outlined"
        fullWidth
        label="Courriel"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        required
      />
      {(userExists || password !== "") && (
        <>
          <TextField
            id="outlined-password-input"
            type="password"
            label="Mot de passe"
            fullWidth
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            required
          />
          {newUser && (
            <TextField
              type="password"
              label="Confirmation du mot de passe"
              fullWidth
              onChange={(e) => setConfirmedPassword(e.target.value)}
              value={confirmedPassword}
              required
            />
          )}
        </>
      )}
    </>
  );
};

export default forwardRef(Login);
