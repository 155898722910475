//React
import React, { useEffect, useMemo, useState, Fragment } from "react";
import { useLocation, useParams } from "react-router-dom";
import useDb from "../hooks/useDb";

// used to convert article content to react components
import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import ReactHtmlParser from "react-html-parser";

//Youtube
import ReactPlayer from "react-player";

//Interfaces
import { Testimonial as TestimonialInterface } from "franco-interfaces";

//firebase
import { functions } from "../firebase/firebase";

const TestimonialInfo: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [testimonial, setTestimonial] = useState<TestimonialInterface | null>(
    useLocation().state as TestimonialInterface
  );

  useEffect(() => {
    const IncrementViews = async () => {
      const incrementTestimonialViews = functions.httpsCallable(
        "IncrementTestimonialsViews"
      );
      if (testimonial) await incrementTestimonialViews(testimonial.id);
      else await incrementTestimonialViews(id);
    };
    IncrementViews();
  }, []);

  const testimonialContent = useMemo(() => {
    if (!testimonial?.content) return [];

    const content = draftToHtml(
      convertToRaw(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(testimonial.content))
        ).getCurrentContent()
      ),
      undefined,
      undefined,
      (entity: any) => {
        if (entity.type === "IMAGE") {
          const { alignment, src, caption, height, width } = entity.data;
          return `<figure
                                    style="
                                    display: flex; 
                                    flex-direction: column;
                                    float: ${alignment};
                                    align-items: center"
                            >
                                <img 
                                    src="${src}"
                                    alt="${caption ?? ""}" 
                                    height="${height}" 
                                    width="${width}"
                                />
                                ${
                                  caption &&
                                  `<figcaption>${caption}</figcaption>`
                                }
                            </figure>`;
        }
      }
    );

    const jsx = ReactHtmlParser(content);
    return jsx;
  }, [testimonial]);

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      setTestimonial(
        await useDb<TestimonialInterface>("Testimonials", null).getById(id)
      );
    };
    if (!testimonial) {
      fetchCompanyInfo();
    }
  }, []);

  return (
    <div className="company">
      <div className="company__container">
        <h1 className="company__title">{testimonial?.title}</h1>
        <div className="company__content">
          <img
          className="company__logo"
            src={
              testimonial && testimonial.imageUrl ? testimonial.imageUrl[0] : ""
            }
            alt=""
          />
          <p>{testimonial?.excerpt}</p>
          <div>
            <h3 className="company__title">Vidéo</h3>
            <ReactPlayer
              url={
                testimonial?.link.substring(0, 8) !== "https://"
                  ? "https://" + testimonial?.link
                  : testimonial?.link
              }
              height={305}
              width={500}
              controls={true}
            />
          </div>
          {testimonialContent.map((paragraph: any, index: number) => {
            if (paragraph.props.children.length === 0)
              return (
                <Fragment key={index}>
                  <br />
                </Fragment>
              );
            else return <Fragment key={index}>{paragraph}</Fragment>;
          })}
        </div>
      </div>
    </div>
  );
};

export default TestimonialInfo;
