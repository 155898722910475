import React, { useState, useEffect } from "react";
// Interfaces
import { Blog as BlogInterface } from "franco-interfaces";
//Hooks
import useDb from "../hooks/useDb";

//Custom Components
import BlogList from "./BlogList";

const Blog: React.FC = () => {
  //states
  const [blogs, setBlogs] = useState<BlogInterface[]>([]);

  //DB fetches and stuff
  const getAllBlogs = useDb<BlogInterface>("Blogs", null).getAll;

  useEffect(() => {
    const fetchBlogs = async () => {
      const fetchedBlogs = await getAllBlogs({
        orderBy: [{ field: "createdAt", direction: "desc" }],
      });
      setBlogs(fetchedBlogs);
    };
    fetchBlogs();
  }, []);

  return (
    <div className="vivreSignUp">
      <BlogList blogs={blogs} />
      <div className="noAdress__background"></div>
    </div>
  );
};

export default Blog;
