import React from "react";
import { Link } from 'react-router-dom';

// Mui
import Grid from "@mui/material/Grid";

//Images
import Logo from "../assets/Logo.png";
import Canada from "../assets/patrimoine-canadien.png";

const Footer: React.FC = () => {

    const handleDownload = () => {
        if(navigator.userAgent.match(/Android/i)) {
          document.location.href="https://play.google.com/store/apps/details?id=com.lefranco.frabio";
        } else if(navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
          document.location.href="https://apps.apple.com/us/app/frabio/id1599566866";
        } else {
          document.location.href="https://play.google.com/store/apps/details?id=com.lefranco.frabio";
        }
    }

  return (
    <div className="footer__container">
        <Grid container className="footer__sectionContainer">
            <Grid item sm={12} md={3} className="footer__section">
                <div className="footer__1">
                    <img src={Logo} />
                    <div className="footer__seperator"/>
                </div>
            </Grid>
            <Grid item sm={12} md={3} className="footer__content--container">
                <div className="footer__2">
                    <h6>Informations de contact</h6>
                    <a href="tel:780-465-6581"><span>(780) 465-6581</span></a>
                    <a href="mailto:reception@lefranco.ab.ca"><span>reception@lefranco.ab.ca</span></a>
                </div>
            </Grid>
            <Grid item sm={12} md={3} className="footer__content--container">
                <div className="footer__3">
                    <h6>Navigation rapide</h6>
                    <Link to="/formulaire"><span>S'annoncer</span></Link>
                    <span onClick={handleDownload} style={{cursor: 'pointer'}}>Télécharger l'application mobile</span>
                </div>
            </Grid>
            <Grid item sm={12} md={3}>
                <div className="footer__4">
                    <div className="footer__seperator"/>
                    <img src={Canada} />
                </div>
            </Grid>
        </Grid>
    </div>
  );
};

export default Footer;
