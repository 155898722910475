import React, { Fragment } from "react";
//Hooks
import { useHistory } from "react-router-dom";
//Interface
import { Testimonial as TestimonialInterface } from "franco-interfaces";

interface Props {
  testimonials: TestimonialInterface[];
}

//Mui
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

const TestimonialList: React.FC<Props> = ({ testimonials }: Props) => {
  // Hooks
  const history = useHistory();

  return (
    <Paper className="companyList">
      <List className="companyList__container">
        {testimonials.map((testimonial, i) => {
          return (
            <Fragment key={i}>
              <div
                className={"companyList__list"}
                onClick={() =>
                  history.push(
                    `/témoignage/${testimonials[i].id}`,
                    testimonials[i]
                  )
                }
              >
                <img
                  className="companyList__list__logo"
                  src={
                    testimonial && testimonial.imageUrl
                      ? testimonial.imageUrl[0]
                      : ""
                  }
                  alt=""
                />
                <div className="companyList__list__info">
                  <h4 className="companyList__list__name">
                    {testimonial.title}
                  </h4>
                </div>
              </div>
              <Divider />
            </Fragment>
          );
        })}
      </List>
    </Paper>
  );
};

export default TestimonialList;
