import React, { Dispatch, SetStateAction } from "react";

//Google
import GoogleMapReact, { MapOptions } from "google-map-react";
import { Geolocation } from "@capacitor/geolocation";

//Custom Components
import VivreMarker from "./vivreMarker";

export interface Geolocation {
  geoPoint: { _lat: number; _long: number };
  name?: string;
  logo?: string[];
  status: string;
  busid?: string;
}

interface Props {
  currentLocation?: any;
  businessLocations: Geolocation[];
  fetchBounds: Dispatch<SetStateAction<any>>;
  id?: string;
}

const Map: React.FC<Props> = ({
  businessLocations,
  fetchBounds,
  id,
}) => {
  //Functions
  const mapUpdates = (map: any) => {
    fetchBounds({
      northEastLat: map.getBounds().getNorthEast().lat(),
      northEastLng: map.getBounds().getNorthEast().lng(),
      southWestLat: map.getBounds().getSouthWest().lat(),
      southWestLng: map.getBounds().getSouthWest().lng(),
    });
    google.maps.event.addListener(map, "idle", () => {
      fetchBounds({
        northEastLat: map.getBounds().getNorthEast().lat(),
        northEastLng: map.getBounds().getNorthEast().lng(),
        southWestLat: map.getBounds().getSouthWest().lat(),
        southWestLng: map.getBounds().getSouthWest().lng(),
      });
    });
  };

  return (
    <div id={id} className="map">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyDyQK6EzEgb0W8j0HSXSaICLBlvN9jZk-A",
        }}
        // Default center change to fixed position: Calgary
        defaultCenter={{ lat: 51.0447, lng: -114.0719 }}
        defaultZoom={5}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => mapUpdates(map)}
        options={
          {
            disableDefaultUI: true,
            mapId: "b4689148d50a5238",
          } as MapOptions
        }
      >
        {businessLocations?.map((business) => (
          <VivreMarker
            lat={business.geoPoint._lat}
            lng={business.geoPoint._long}
            name={business.name}
            logo={business.logo}
            status={business.status}
            busid={business.busid}
          />
        ))}
      </GoogleMapReact>
      )
    </div>
  );
};

export default Map;
