import React, { createContext, useEffect, useState } from "react";

//Interfaces
import { Tag } from "franco-interfaces";

// Hooks
import useDb from "./hooks/useDb";

export const Context = createContext<any>(null);

export const ContextProvider: React.FC = ({ children }) => {
  // DB
  const getAllTags = useDb<Tag>("Tags", null).getAll;

  // States
  const [tags, setTags] = useState<Tag[]>([]);

  // Fetch all Tags
  useEffect(() => {
    const fetchAllTags = async () => {
      const fetchedTags: Tag[] = await getAllTags({});
      setTags(fetchedTags);
    };
    fetchAllTags();
  }, []);

  // Helper function to used in Lire and ecouter to find color of Tag
  const findTagColor = (tagType: string): string => {
    let colorString = "";
    tags.forEach((tag: Tag) => {
      if (tag.id === tagType) {
        colorString = tag.color;
      }
    });
    return colorString;
  };

  return (
    <Context.Provider
      value={{
        setTags,
        tags,
        findTagColor,
      }}
    >
      {children}
    </Context.Provider>
  );
};
