//React
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CgWebsite } from "react-icons/cg";
import { BsFacebook, BsTwitter, BsInstagram, BsLinkedin } from "react-icons/bs";
import useDb from "../hooks/useDb";
import { Vivre } from "franco-interfaces";

// React share
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

//Interfaces
import { Vivre as VivreInterface } from "franco-interfaces";

//Carousel
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

//images
import frenchLevelFilled from "../assets/frenchLevelFilled.png";
import frenchLevelOutline from "../assets/frenchLevelOutline.png";

const Company: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [vivre, setVivre] = useState<VivreInterface | null>(
    useLocation().state as VivreInterface
  );

  const frenchLevels = [1, 2, 3, 4];
  const frenchLevelIcons = [frenchLevelFilled, frenchLevelOutline];

  const hasLinks = () => {
    return (
      (vivre && vivre.website) ||
      (vivre && vivre.facebook) ||
      (vivre && vivre.instagram) ||
      (vivre && vivre.twitter) ||
      (vivre && vivre.linkedIn)
    );
  };

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      setVivre(await useDb<Vivre>("Vivre", null).getById(id));
    };
    if (!vivre) {
      fetchCompanyInfo();
    }
  }, []);

  const properExplanation = (level:any) => {
    if(level === "1") {
     return "Appuie la francophonie, mais ne garantit aucun services en français"
    }else if(level === "2") {
      return "Documentation disponible en français"
    }else if(level === "3") {
      return `Services en français offerts à l'occasion ou sur demande:  ${vivre?.frenchExplanation}`
    }else {
      return "Services en français en tout temps"
    }
  }

  return (
    <div className="company">
      <div className="company__container">
        <h1 className="company__title">{vivre?.name}</h1>
        <div className="company__content">
          <img src={vivre?.logo[0]} alt="" />
          <div className="company__info">
            <p className="company__p">
              {vivre && vivre?.address && vivre?.address.addressNumber ? (
                <>
                  <span className="company__span">Adresse: </span>
                  <span>
                    {vivre?.address.addressNumber} {vivre?.address.street},{" "}
                    {vivre?.address.city}, {vivre?.address.province}
                  </span>
                </>
              ) : (
                ""
              )}
            </p>
            <p className="company__p">
              <span className="company__span">Numéro de téléphone: </span>
              {vivre?.phone}
            </p>
            <p className="company__p">
              <span className="company__span">Description: </span>
              {vivre?.description}
            </p>
            <p className="company__p">
              <span className="company__span">Catégorie: </span>
              {vivre?.category}
            </p>
            <p className="company__p">
              <span className="company__span">Adresse courriel: </span>
              {vivre?.email}
            </p>
            <p className="company__p">
              <span className="company__span">Niveau de français: </span>
              {frenchLevels.map((level, i) => {
                return (
                  <React.Fragment key={i}>
                    {parseInt(vivre ? vivre.french : "") >= i + 1 ? (
                      <img
                        src={frenchLevelIcons[0]}
                        height="20px"
                        width="20px"
                        alt=""
                      />
                    ) : (
                      <img
                        src={frenchLevelIcons[1]}
                        height="20px"
                        width="20px"
                        alt=""
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </p>
 
              <p className="company__p">
                <span className="company__span">
                  Explication du niveau de français:{" "}
                </span>
                {properExplanation(vivre?.french)}
              </p>

            {vivre?.existingHours && (
              <div style={{ marginTop: "40px" }}>
                <h3 style={{ textAlign: "center" }}>Heures d'ouvertures</h3>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p>
                    <span className="company__span">Lundi: </span>
                    {vivre?.hours.monday}
                  </p>
                  <p>
                    <span className="company__span">Mardi: </span>{" "}
                    {vivre?.hours.tuesday}
                  </p>
                  <p>
                    <span className="company__span">Mercredi: </span>{" "}
                    {vivre?.hours.wednesday}
                  </p>
                  <p>
                    <span className="company__span">Jeudi: </span>{" "}
                    {vivre?.hours.thursday}
                  </p>
                  <p>
                    <span className="company__span">Vendredi: </span>{" "}
                    {vivre?.hours.friday}
                  </p>
                  <p>
                    <span className="company__span">Samedi: </span>{" "}
                    {vivre?.hours.saturday}
                  </p>
                </div>
                <p>
                  <span className="company__span">Dimanche: </span>{" "}
                  {vivre?.hours.sunday}
                </p>
              </div>
            )}
          </div>
          {hasLinks() && (
            <>
              <h3 style={{ textAlign: "center" }}>Liens</h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                {vivre && vivre.website && (
                  <a href={vivre.website}>
                    <CgWebsite size={40} />
                  </a>
                )}
                {vivre && vivre.facebook && (
                  <a href={vivre.facebook}>
                    <BsFacebook size={40} />
                  </a>
                )}
                {vivre && vivre.instagram && (
                  <a href={vivre.instagram}>
                    <BsInstagram size={40} />
                  </a>
                )}
                {vivre && vivre.twitter && (
                  <a href={vivre.twitter}>
                    <BsTwitter size={40} />
                  </a>
                )}
                {vivre && vivre.linkedIn && (
                  <a href={vivre.linkedIn}>
                    <BsLinkedin size={40} />
                  </a>
                )}
              </div>
            </>
          )}
          <div className="company__media">
            <h3>Partager</h3>
            <div className="company__media__icon">
              <FacebookShareButton url={window.location.href}>
                <FacebookIcon size={40} round />
              </FacebookShareButton>
              <TwitterShareButton url={window.location.href}>
                <TwitterIcon size={40} round />
              </TwitterShareButton>
              <EmailShareButton url={window.location.href}>
                <EmailIcon size={40} round />
              </EmailShareButton>
            </div>
          </div>
        </div>
        {vivre && vivre?.imageUrl.length > 0 && (
          <>
            <h3 style={{ textAlign: "center" }}>Images associées au service</h3>
            <div
              style={{
                height: "auto",
                width: "auto",
                display: "flex",
                justifyContent: "center",
                margin: 0,
              }}
            >
              <Carousel
                showThumbs={false}
                showIndicators={false}
                swipeable
                autoPlay
                infiniteLoop
                emulateTouch
              >
                {vivre?.imageUrl.map((image) => (
                  <div>
                    <img src={image} />
                  </div>
                ))}
              </Carousel>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Company;
