import React, { Dispatch, SetStateAction, useEffect } from "react";

//MUI
import TextField from "@mui/material/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";

interface Props {
  firstName: string;
  setFirstName: Dispatch<SetStateAction<string>>;
  lastName: string;
  setLastName: Dispatch<SetStateAction<string>>;
  plan: any;
  setPlan: Dispatch<SetStateAction<number>>;
  setSubscriptionPlan: Dispatch<SetStateAction<string>>;
  setQuota: Dispatch<SetStateAction<number>>;
}

const plans = [
  "1 événement par année - 79$",
  "1 événement par saison (4x par année) - 139$",
  "1 événement par mois (12x par année) - 179$",
  "1 événement par semaine (52 fois par année) - 229$ par année",
];

const AnnouncerInfo = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  plan,
  setPlan,
  setSubscriptionPlan,
  setQuota,
}: Props) => {

  return (
    <>
      <TextField
        id="outlined"
        fullWidth
        label="Prénom"
        onChange={(e) => setFirstName(e.target.value)}
        value={firstName}
        variant="standard"
        required
      />
      <TextField
        id="outlined"
        fullWidth
        label="Nom de famille"
        onChange={(e) => setLastName(e.target.value)}
        value={lastName}
        variant="standard"
        required
      />
      <Box>
        <InputLabel>Veuillez selectionner votre plan</InputLabel>
        <Select
          color="secondary"
          value={plan}
          onChange={(e: any) => {
            if (e.target.value === 0) setQuota(1);
            else if (e.target.value === 1) setQuota(4);
            else if (e.target.value === 2) setQuota(12);
            else if (e.target.value === 3) setQuota(52);
            setPlan(e.target.value);
          }}
        >
          {plans.map((r, i) => (
            <MenuItem key={i} value={i} onClick={() => setSubscriptionPlan(r)}>
              {r}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </>
  );
};

export default AnnouncerInfo;
