import { WhereFilterOp } from "@firebase/firestore-types";
import * as dbUtils from "../firebase/requests";

import { User, Log } from "franco-interfaces";

import { db, firestore } from "../firebase/firebase";

// utils
import { genRandomBetween } from "../utils/math";

export interface GetAllOptions {
  filters?: dbUtils.Filter[];
  orderBy?: dbUtils.OrderBy | dbUtils.OrderBy[];
  isDeleted?: boolean;
  allData?: boolean;
  hasLimit?: boolean;
  startAfter?: any;
  limitNum?: number;
}

interface onSnapshotOptions {
  callback: Function;
  id?: string;
  orderBy?: dbUtils.OrderBy | dbUtils.OrderBy[];
  filters?: dbUtils.Filter[];
  acceptDeleted?: boolean;
}

function useDb<T>(
  collection: string,
  currentUser: User | null,
  document?: string
) {
  const convertCollectionToFrench = () => {
    switch (collection) {
      case "Users":
        return "de l'utilisateur";
      case "Authors":
        return "de l'auteur";
      case "Tags":
        return "de l'étiquette";
      case "Categories":
        return "de la catégorie";
      case "DossierTemporals":
        return "du dossier temporel";
      case "Ads":
        return "de la publicité";
      default:
        return "d'une entrée dans une collection inconnue";
    }
  };

  const firstFetch = (callback: Function, acceptDeleted = false) => {
    return dbUtils.firstFetch(collection, callback, acceptDeleted);
  };

  const fetchNextPage = (
    callback: Function,
    item: T,
    acceptDeleted = false
  ) => {
    return dbUtils.fetchNextPage(collection, callback, item, acceptDeleted);
  };

  const fetchLastPage = (
    callback: Function,
    item: T,
    acceptDeleted = false
  ) => {
    return dbUtils.fetchLastPage(collection, callback, item, acceptDeleted);
  };

  const getAll: (options: GetAllOptions) => Promise<T[]> = ({
    filters = [],
    orderBy = { field: "createdAt", direction: "desc" },
    isDeleted = false,
    allData = false,
    hasLimit = false,
    limitNum = 0,
    startAfter = undefined,
  }) => {
    return dbUtils.getAll(
      collection,
      filters,
      Array.isArray(orderBy) ? orderBy : [orderBy],
      isDeleted,
      allData,
      hasLimit,
      limitNum,
      startAfter
    );
  };

  const getById: (id: string) => Promise<T> = (id) => {
    return dbUtils.getById(collection, id);
  };

  const updateDoc: (doc: T) => Promise<T> = async (doc) => {
    const res: Promise<T> = dbUtils.update(collection, doc);

    const entry = await dbUtils.getById(collection, (doc as any).id);

    return res;
  };

  const createDoc: (doc: T, id?: string) => Promise<T> = async (doc, id) => {
    const res: Promise<T> = dbUtils.create(collection, doc, id);
    return res;
  };

  const onSnapshot = ({
    callback,
    orderBy = { field: "createdAt", direction: "desc" },
    filters = [],
    id,
    acceptDeleted = false,
  }: onSnapshotOptions) => {
    return dbUtils.onSnapshot(
      collection,
      callback,
      Array.isArray(orderBy) ? orderBy : [orderBy],
      filters,
      id,
      acceptDeleted
    );
  };

  const onSnapshotWithDoc = (callback: Function) => {
    return dbUtils.onSnapshotWithDoc(document ? document : "", callback);
  };

  /*
   * Fetch a number of random elemnents from the collection in DB
   *
   * @param num number   number of elements to fetch
   *
   * @returns an array of elements from the collection*/
  const fetchRandom = async (num = 3, filters: dbUtils.Filter[] = []) => {
    const initialRand = genRandomBetween(0, 100000);

    // utility function to fetch a random element from the given collection
    const fetchRand = (operator: WhereFilterOp, value: number) =>
      getAll({
        filters: [
          {
            field: "random",
            operator,
            value,
          },
          ...filters,
        ],
        orderBy: { field: "random", direction: "asc" },
        hasLimit: true,
        limitNum: num,
      });

    const firstRes = await fetchRand("<=", initialRand);
    const secondRes = await fetchRand(">=", initialRand);

    const res = [...firstRes, ...secondRes];

    const randomIndices: number[] = [];

    for (let i = 0; i < num; i++) {
      let rand = genRandomBetween(0, res.length - 1);

      while (randomIndices.includes(rand))
        rand = genRandomBetween(0, res.length - 1);

      randomIndices.push(rand);
    }

    return res.filter((_, i: number) => randomIndices.includes(i));
  };

  const increment = (docId: string, field: keyof T) => {
    const inc = firestore.FieldValue.increment(1);
    const docRef = db.collection(collection).doc(docId);
    docRef.update({ [field]: inc });
  };

  return {
    firstFetch,
    fetchNextPage,
    fetchLastPage,
    getAll,
    getById,
    updateDoc,
    createDoc,
    onSnapshot,
    onSnapshotWithDoc,
    fetchRandom,
    increment,
  };
}

export default useDb;
