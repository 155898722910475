import {
  EditorState,
  ContentState,
  convertToRaw,
  RawDraftContentState,
} from "draft-js";

import { blocktypeRenderer } from "./renderers";
import { tokenizeBlock } from "./utils";

export default class DraftParser {
  // might not need
  private _editorState: EditorState;
  private _contentState: ContentState;

  // most def need
  private _rawData: RawDraftContentState;

  constructor(es: EditorState) {
    this._editorState = es;
    this._contentState = this._editorState.getCurrentContent();

    this._rawData = convertToRaw(this._contentState);
  }

  private tokenize() {
    const { blocks, entityMap } = this._rawData;

    const tokens: any = [];

    for (let i = 0; i < blocks.length; i++) {
      let j = i;
      const b = blocks[i];

      if (b.type === "unordered-list-item") {
        while (blocks[j] && blocks[j].type === "unordered-list-item") {
          const data = tokenizeBlock(blocks[j], entityMap);
          tokens[i] = tokens[i] ? [...tokens[i], data] : [data];
          j++;
        }
        i = j - 1;
      } else if (b.type === "ordered-list-item") {
        while (blocks[j] && blocks[j].type === "ordered-list-item") {
          const data = tokenizeBlock(blocks[j], entityMap);
          tokens[i] = tokens[i] ? [...tokens[i], data] : [data];
          j++;
        }
        i = j - 1;
      } else if (b.type === "color_box") {
        const { type, data } = b;
        const color = data?.color;

        const { text } = tokenizeBlock(b, entityMap);
        tokens.push({ type, data: { text, color } });
      } else if (b.type === "atomic") {
        const { type, data } = entityMap[b.entityRanges[0].key];
        tokens.push({ type, data });
      } else {
        tokens.push(tokenizeBlock(b, entityMap));
      }
    }

    return tokens;
  }

  parse(): string {
    const html = this.tokenize().reduce(
      (acc: string, token: any, i: number) => {
        let type = "";
        let value: any = "";
        if (Array.isArray(token)) {
          type = token[0].type;
          value = token.map((v) => v.text);
        }
        if ("text" in token) {
          type = token.type;
          value = token.text;
        }
        if ("data" in token) {
          type = token.type;
          value = token.data;
        }

        if (!Object.keys(blocktypeRenderer).includes(type))
          throw new Error(
            `${this.constructor.name}: Error in type ${type} of token index: ${i}`
          );

        acc += blocktypeRenderer[type](value);
        return acc;
      },
      ""
    );

    return html;
  }
}
