import React, { FC } from "react";

import { Route, Switch } from "react-router-dom";

// custom component
import VivreSignUp from "../pages/VivreSignUp";
import Company from "../pages/Company";
import Form from "../pages/Form";
import Blog from "../pages/Blog";
import BlogInfo from "../pages/BlogInfo";
import Testimonial from "../pages/Testimonial";
import TestimonialInfo from "../pages/TestimonialInfo";
import AnnouncerForm from "../pages/AnnouncerForm";
import Events from "../pages/Events";
import Event from "../pages/Event";

type RouteProps = { path: string; Component: FC; exact: boolean };
const routes: RouteProps[] = [
  { path: "/", Component: VivreSignUp, exact: true },
  { path: "/formulaire", Component: Form, exact: false },
  { path: "/vivre/:id", Component: Company, exact: false },
  { path: "/actualité", Component: Blog, exact: true },
  { path: "/actualité/:id", Component: BlogInfo, exact: false },
  { path: "/témoignage", Component: Testimonial, exact: true },
  { path: "/témoignage/:id", Component: TestimonialInfo, exact: false },
  { path: "/annonceur", Component: AnnouncerForm, exact: false },
  { path: "/calendrier", Component: Events, exact: true },
  { path: "/calendrier/:id", Component: Event, exact: false },
];

const Routes: React.FC = () => {
  return (
    <Switch>
      {routes.map(({ path, Component, exact }: RouteProps) => (
        <Route exact={exact} path={path}>
          <Component />
        </Route>
      ))}
    </Switch>
  );
};

export default Routes;
