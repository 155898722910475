import React, { Fragment } from "react";
//Hooks
import { useHistory } from "react-router-dom";
//Interface
import { Blog as BlogInterface } from "franco-interfaces";

interface Props {
  blogs: BlogInterface[];
}

//Mui
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

const BlogList: React.FC<Props> = ({ blogs }: Props) => {
  // Hooks
  const history = useHistory();

  return (
    <Paper className="companyList">
      <List className="companyList__container">
        {blogs.map((blog, i) => {
          return (
            <Fragment key={i}>
              <div
                className={"companyList__list"}
                onClick={() =>
                  history.push(`/actualité/${blogs[i].id}`, blogs[i])
                }
              >
                <img
                  className="companyList__list__logo"
                  src={blog && blog.imageUrl ? blog.imageUrl[0] : ""}
                  alt=""
                />
                <div className="companyList__list__info">
                  <h4 className="companyList__list__name">{blog.title}</h4>
                </div>
              </div>
              <Divider />
            </Fragment>
          );
        })}
      </List>
    </Paper>
  );
};

export default BlogList;
