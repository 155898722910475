//React
import React, { useEffect, useMemo, useState, Fragment } from "react";
import { useLocation, useParams } from "react-router-dom";
import useDb from "../hooks/useDb";

// used to convert article content to react components
import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import ReactHtmlParser from "react-html-parser";

//Interfaces
import { Blog as BlogInterface } from "franco-interfaces";

//firebase
import { functions } from "../firebase/firebase";

const BlogInfo: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [blog, setBlog] = useState<BlogInterface | null>(
    useLocation().state as BlogInterface
  );

  useEffect(() => {
    const IncrementViews = async () => {
      const incrementBlogViews = functions.httpsCallable("IncrementBlogsViews");
      if (blog) await incrementBlogViews(blog.id);
      else await incrementBlogViews(id);
    };
    IncrementViews();
  }, []);

  const blogContent = useMemo(() => {
    if (!blog?.content) return [];

    const content = draftToHtml(
      convertToRaw(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(blog.content))
        ).getCurrentContent()
      ),
      undefined,
      undefined,
      (entity: any) => {
        if (entity.type === "IMAGE") {
          const { alignment, src, caption, height, width } = entity.data;
          return `<figure
                                    style="
                                    display: flex; 
                                    flex-direction: column;
                                    float: ${alignment};
                                    align-items: center"
                            >
                                <img 
                                    src="${src}"
                                    alt="${caption ?? ""}" 
                                    height="${height}" 
                                    width="${width}"
                                />
                                ${
                                  caption &&
                                  `<figcaption>${caption}</figcaption>`
                                }
                            </figure>`;
        }
      }
    );

    const jsx = ReactHtmlParser(content);
    return jsx;
  }, [blog]);

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      setBlog(await useDb<BlogInterface>("Blogs", null).getById(id));
    };
    if (!blog) {
      fetchCompanyInfo();
    }
  }, []);

  return (
    <div className="company">
      <div className="company__container">
        <h1 className="company__title">{blog?.title}</h1>
        <div className="company__content">
          <img src={blog && blog.imageUrl ? blog.imageUrl[0] : ""} alt="" />
          <p>{blog?.excerpt}</p>
          {blogContent.map((paragraph: any, index: number) => {
            if (paragraph.props.children.length === 0)
              return (
                <Fragment key={index}>
                  <br />
                </Fragment>
              );
            else return <Fragment key={index}>{paragraph}</Fragment>;
          })}
        </div>
      </div>
    </div>
  );
};

export default BlogInfo;
