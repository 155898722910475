import React, { useEffect, useState } from "react";
import { NavLink, Link, useLocation} from "react-router-dom";
import { MdMenu, MdClose } from "react-icons/md";

//Firebase
import { auth } from "../firebase/firebase";

// Mui
import Button from "@mui/material/Button";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

//Images
import Logo from "../assets/Logo.png";
import { useFetchUser } from "../hooks/useFetchUser";

const Navbar: React.FC = () => {
  const [siteWidth, setSiteWidth] = useState(window.innerWidth);
  const [clicked, setClicked] = useState(false);
  const { currentUser } = useFetchUser();
  const { pathname } = useLocation();

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleSize = () => {
    setSiteWidth(window.innerWidth);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClickMenu = (event:any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = () => {
    if (navigator.userAgent.match(/Android/i)) {
      document.location.href =
        "https://play.google.com/store/apps/details?id=com.lefranco.frabio";
    } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      document.location.href =
        "https://apps.apple.com/us/app/frabio/id1599566866";
    } else {
      document.location.href =
        "https://play.google.com/store/apps/details?id=com.lefranco.frabio";
    }
  };

  window.addEventListener("resize", handleSize);

  useEffect(() => {
    setClicked(false);
  }, [pathname]);

  return (
    <>
      {siteWidth > 1070 ? (
        <div className="nav">
          <div className="nav__main">
            <Link to="/" className="nav__logo clickable__tag">
              <img src={Logo} alt="logo" />
            </Link>
            <div className="nav__nav">
              <NavLink
                exact
                className="clickable__tag"
                activeClassName="clickable__active"
                to="/"
              >
                <p>Service localisé</p>
              </NavLink>

              {/* <NavLink
                exact
                className="clickable__tag"
                activeClassName="clickable__active"
                to="/témoignage"
              >
                <p>Témoignages</p>
              </NavLink> */}
              {/* <NavLink
                exact
                className="clickable__tag"
                activeClassName="clickable__active"
                to="/actualité"
              >
                <p>Actualité</p>
              </NavLink> */}

              <NavLink
                exact
                className="clickable__tag"
                activeClassName="clickable__active"
                to="/calendrier"
              >
                <p>Calendrier</p>
              </NavLink>

              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickMenu}
                className="frabio__borderboxshadow nav__secondaryCta"
                variant="outlined"
              >
                S'afficher dans Frabio
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleClose}>{currentUser === null ? <Link to="/annonceur">Ajouter un événement</Link>: <a href="https://admin.frab.io/">Ajouter un événement</a>}</MenuItem>
                <MenuItem onClick={handleClose}><Link to="/formulaire">Inscrire un service</Link></MenuItem>
              </Menu>

              <Button
                variant="contained"
                className="frabio__borderboxshadow nav__cta"
                onClick={handleDownload}
              >
                Télécharger l'application
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="nav">
          <div className="nav__main">
            <Link to="/" className="nav__logo clickable__tag">
              <img src={Logo} alt="logo" />
            </Link>
            <div className="hbg-menu" onClick={handleClick}>
              {clicked ? <MdClose /> : <MdMenu />}
            </div>
            <div className={clicked ? "nav__menu active" : "nav__menu"}>
              <div className="nav__nav">
                <NavLink
                  exact
                  className="clickable__tag"
                  activeClassName="clickable__active"
                  to="/"
                >
                  Service localisé
                </NavLink>
                <NavLink
                exact
                className="clickable__tag"
                activeClassName="clickable__active"
                to="/calendrier"
              >
                <p>Calendrier</p>
              </NavLink>

              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickMenu}
                className="frabio__borderboxshadow nav__secondaryCta"
                variant="outlined"
              >
                S'afficher dans Frabio
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleClose}>{currentUser === null ? <Link to="/annonceur">Ajouter un événement</Link>: <a href="https://admin.frab.io/">Ajouter un événement</a>}</MenuItem>
                <MenuItem onClick={handleClose}><Link to="/formulaire">Inscrire un service</Link></MenuItem>
              </Menu>

                <Button
                  variant="contained"
                  className="frabio__borderboxshadow nav__cta"
                  onClick={handleDownload}
                >
                  Télécharger l'application
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
