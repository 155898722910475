import { useImperativeHandle, Ref, forwardRef, useState } from "react";
// Interfaces
import { Vivre, User } from "franco-interfaces";
// Firebase
import { functions } from "../firebase/firebase";
// Stripe
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
// Custom components
import { emailRegex } from "../utils/regex";
// MUI
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const requiredMessage = "Ce champ est requis";
const subScriptionSchema = yup.object({
  name: yup.string().required(requiredMessage),
  email: yup
    .string()
    .matches(emailRegex, "Courriel invalide")
    .required(requiredMessage),
  phone: yup.string(),
});

interface Props {
  product: any;
  customer?: User;
}

const Checkout = ({ product, customer }: Props, ref: Ref<any>) => {
  useImperativeHandle(ref, () => ({
    handleSubmit: handleSubmit(onSubmit),
    isSubmitSuccessful: isSubmitSuccessful,
  }));
  //  State
  const [loading, setLoading] = useState(false);
  //  Hooks
  const stripe = useStripe();
  const elements = useElements();
  const cardElement = elements ? elements.getElement(CardElement) : "";
  //Validation
  const {
    handleSubmit,
    setError,
    control,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    resolver: yupResolver(subScriptionSchema),
    mode: "onChange",
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    if (!stripe || !elements || !cardElement) {
      setError("name", {
        message: "Le nom est manquant",
      });
      return;
    }

    if (!(!!data.name || !!data.email)) {
      setError("name", {
        message: "Nom ou courriel manquant",
      });
      return;
    }
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: data.name,
        email: data.email,
      },
    });

    if (result.error) {
      // Show error in payment form
      setError("name", {
        message: result.error.message,
      });
    } else {
      const payload = {
        name: data.name,
        email: data.email,
        customer,
        product,
        paymentMethod: result.paymentMethod.id,
      };

      let res: any;
      if (product.french) {
        const subscribe = functions.httpsCallable("SubscribeVivre");
        res = await subscribe(payload);
      } else {
        const subscribe = functions.httpsCallable("SubscribeAnnouncer");
        res = await subscribe(payload);
      }

      if (res.data?.error) {
        setLoading(false);
        setError("name", {
          message: "Un problème s'est produit. Veuillez réessayer",
        });
        return;
      }

      //VERIFY IF THIS CAN BE MOVED INTO CLOUD FUNCTIONS
      const { client_secret, status } = res.data;

      if (status === "requires_action") {
        stripe.confirmCardPayment(client_secret).then(function (result) {
          if (result.error) {
            setError("name", {
              message: "Un problème s'est produit. Veuillez réessayer",
            });
          }
        });
      }
    }
    setLoading(false);
  };

  return (
    <div className="checkout">
      <Box sx={{ visibility: loading ? "hidden" : "visible" }}>
        <h4>Informations de paiement</h4>
        <CardElement
          className="checkout__stripe"
          options={{ hidePostalCode: true }}
        />
        <br />
        <Controller
          name="name"
          defaultValue={""}
          control={control}
          render={({ field }) => (
            <TextField
              error={!!errors.name}
              helperText={errors.name?.message}
              fullWidth
              margin="dense"
              label="Nom"
              {...field}
            />
          )}
        />
        <Controller
          name="email"
          defaultValue={""}
          control={control}
          render={({ field }) => (
            <TextField
              error={!!errors.email}
              helperText={errors.email?.message}
              fullWidth
              margin="dense"
              label="Courriel"
              {...field}
            />
          )}
        />
        <Controller
          name="address"
          defaultValue={""}
          control={control}
          render={({ field }) => (
            <TextField
              error={!!errors.description}
              helperText={errors.description?.message}
              fullWidth
              margin="dense"
              label="Adresse"
              {...field}
            />
          )}
        />
        <Controller
          name="province"
          defaultValue={""}
          control={control}
          render={({ field }) => (
            <TextField
              error={!!errors.description}
              helperText={errors.description?.message}
              fullWidth
              margin="dense"
              label="Province"
              {...field}
            />
          )}
        />
        <Controller
          name="city"
          defaultValue={""}
          control={control}
          render={({ field }) => (
            <TextField
              error={!!errors.description}
              helperText={errors.description?.message}
              fullWidth
              margin="dense"
              label="Ville"
              {...field}
            />
          )}
        />
        <Controller
          name="postal code"
          defaultValue={""}
          control={control}
          render={({ field }) => (
            <TextField
              error={!!errors.description}
              helperText={errors.description?.message}
              fullWidth
              margin="dense"
              label="Code Postal"
              {...field}
            />
          )}
        />
      </Box>
      <Box
        sx={{
          visibility: loading ? "visible" : "hidden",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    </div>
  );
};

export default forwardRef(Checkout);
