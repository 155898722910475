import { useState, useRef, ChangeEvent, Dispatch, SetStateAction } from "react";

import { FaTrash } from "react-icons/fa";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

import { useSnackbar } from "notistack";

import Avatar from "./Avatar";

import { deleteImageFromStorage, getDownloadUrls } from "./utils";

interface ImageUploaderProps {
  imageUrls: string[];
  onChange: (imageUrls: string[]) => void;
  storagePath: string;
  maxSizeMB?: number;
  maxWidthOrHeight?: number;
  width?: string;
  height?: string;
  borderRadius?: string;
  uploadCallback?: (f: File) => any;
  multipleImages?: boolean;
}

const ImageUploader = ({
  imageUrls,
  onChange,
  storagePath,
  maxSizeMB = 1,
  maxWidthOrHeight = 300,
  width = "250",
  height = "250",
  borderRadius = "50",
  uploadCallback,
  multipleImages = false,
}: ImageUploaderProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const inputRef = useRef<HTMLInputElement>(null);
  const [imgFiles, setImgFiles] = useState<File[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const handleAvatarChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const file: File = e.target.files[0];
    if (multipleImages) {
      setImgFiles((f: File[]) => [...f, file]);
    } else {
      setImgFiles([file]);
    }

    const url = URL.createObjectURL(file);

    if (multipleImages) {
      onChange([...imageUrls, url]);
    } else {
      onChange([url]);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const onAccept = async () => {
    setProcessing(true);
    const toUpload: number[] = imageUrls.reduce(
      (acc: number[], url: string, index: number) => {
        if (url.includes("firebase")) return acc;
        acc.push(index);
        return acc;
      },
      []
    );

    const imgFilesToUpload: File[] = imgFiles.filter((_, i: number) =>
      toUpload.includes(i)
    );

    try {
      const stored = await getDownloadUrls(
        storagePath,
        imgFilesToUpload,
        maxSizeMB,
        maxWidthOrHeight,
        uploadCallback
      );
      const removedStoredImages = imageUrls.filter(
        (_, i: number) => !toUpload.includes(i)
      );
      const newImageUrls = [...removedStoredImages, ...stored];
      onChange(newImageUrls);
    } catch (e) {
      console.error(e);
      enqueueSnackbar(
        "Erreur lors du téléversement des images. Veuillez rééssayez",
        { variant: "error" }
      );
    } finally {
      setProcessing(false);
      onClose();
    }
  };

  const removeImage = async (i: number) => {
    if (imageUrls[i].includes("firebase")) {
      setProcessing(true);
      try {
        await deleteImageFromStorage(imageUrls[i]);
      } catch (e: any) {
        console.error(`Could not delete the image ${imageUrls[i]}`, e);
        enqueueSnackbar(
          "Erreur lors de la suppression de l'image. Veuillez rééssayez",
          { variant: "error" }
        );
        return;
      } finally {
        setProcessing(false);
      }
    }

    setImgFiles((files: File[]) => {
      return files.filter((_, index: number) => index !== i);
    });

    const removedImage = imageUrls.filter((_, index: number) => index !== i);
    onChange(removedImage);
  };

  return (
    <div className="avatar__container" style={{ borderRadius }}>
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        className="avatar__input"
        onChange={handleAvatarChange}
      />
      <Avatar
        onClick={() => {
          setOpen(true);
        }}
        borderRadius={borderRadius}
        width={width}
        height={height}
        src={imageUrls[0]}
      />
      <span className="avatar__upload">Modifier</span>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Modifier Images</DialogTitle>
        <DialogContent>
          <div className="avatar__grid">
            <div
              className="avatar__container"
              onClick={() => {
                if (null !== inputRef.current) inputRef.current.click();
              }}
            >
              <Avatar borderRadius="0" height={height} src={imageUrls[0]} />
              <span className="avatar__upload">Ajouter</span>
            </div>
            <div>
              {imageUrls.map((url: string, i: number) => {
                return (
                  <div
                    key={i}
                    className="avatar__grid__item"
                    onClick={() => removeImage(i)}
                  >
                    <img key={i} src={url} />
                    <div className="overlay">
                      <FaTrash />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Backdrop open={processing} style={{ zIndex: 1111 }}>
            <CircularProgress color="primary" />
          </Backdrop>
          {!!imgFiles.length && (
            <Button
              className="form__button--next"
              variant="contained"
              disabled={processing}
              onClick={onAccept}
            >
              téléverser
            </Button>
          )}
          <Button
            className="form__button--back"
            variant="outlined"
            onClick={onClose}
          >
            annuler
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImageUploader;
