import React, { useEffect, useState, useMemo, Fragment } from "react";
import { useParams, useLocation } from "react-router-dom";

//interfaces
import { Event as EventInterface } from "franco-interfaces";

//custom
import useDb from "../hooks/useDb";
import VivreMarker from "../components/vivreMarker";
import EventsList from "../components/EventsList";

//GOOGLE
import GoogleMapReact, { MapOptions } from "google-map-react";

//assets
import free from "../assets/free.svg";
import inPerson from "../assets/inPerson.svg";
import notFree from "../assets/dollarSignIcon.svg";
import virtual from "../assets/virtual.svg";

// used to convert article content to react components
import { EditorState, convertFromRaw } from "draft-js";
import ReactHtmlParser from "react-html-parser";
import DraftParser from "../utils/draft/draftParser";

//MUI
import Button from "@mui/material/Button";

// moment
import moment from "moment";
import 'moment/locale/fr';

const Event: React.FC = () => {
  // hooks
  const getRelatedEvents = useDb<EventInterface>("Events", null).getAll;
  const { id } = useParams<{ id: string }>();
  // states
  const [event, setEvent] = useState<EventInterface | null>(
    useLocation().state as EventInterface
  );
  const [relatedEvents, setRelatedEvents] = useState<EventInterface[]>([]);

  useEffect(() => {
    const fetchEventInfo = async () => {
      setEvent(await useDb<EventInterface>("Events", null).getById(id));
    };
    if (!event) {
      fetchEventInfo();
    }
  }, []);

  // moment language
  moment.locale("fr");

  useEffect(() => {
    const fetchRelatedEvents = async () => {
      let fetchedEvents: any = [];
      fetchedEvents = await getRelatedEvents({
        orderBy: { field: "dates.releaseDate", direction: "desc" },
        filters: [
          {
            field: "dates.releaseDate",
            operator: "<=",
            value: new Date(),
          },
          {
            field: "announcerId",
            operator: "==",
            value: event?.announcerId,
          },
          {
            field: "online",
            operator: "==",
            value: true,
          },
        ],
        hasLimit: true,
        limitNum: 5,
      });
      const filteredFetchedEvents = fetchedEvents.filter(
        (e: EventInterface) => {
          if (e.id !== event?.id) {
            return e;
          }
        }
      );

      if (filteredFetchedEvents.length < 4) {
        const numToFetch: number = 4 - filteredFetchedEvents.length + 1;
        const extraFetchedEvents: any = await getRelatedEvents({
          orderBy: { field: "dates.releaseDate", direction: "desc" },
          filters: [
            {
              field: "dates.releaseDate",
              operator: "<=",
              value: new Date(),
            },
            {
              field: "online",
              operator: "==",
              value: true,
            },
            {
              field: "tags",
              operator: "array-contains",
              value: event?.tags[0],
            },
          ],
          hasLimit: true,
          limitNum: numToFetch,
        });

        const fetchedEventIds = filteredFetchedEvents.map(
          (event: EventInterface) => {
            return event.id;
          }
        );

        const result = extraFetchedEvents.filter((item: any) => {
          return item.id !== event?.id && !fetchedEventIds.includes(item.id);
        });

        if (result.length === 0) {
          const lastFetch = await getRelatedEvents({
            orderBy: { field: "dates.releaseDate", direction: "desc" },
            filters: [
              {
                field: "dates.releaseDate",
                operator: "<=",
                value: new Date(),
              },
              {
                field: "online",
                operator: "==",
                value: true,
              },
            ],
            hasLimit: true,
            limitNum: numToFetch,
          });

          const filteredEvents = lastFetch.filter((item) => {
            return item.id !== event?.id && !fetchedEventIds.includes(item.id);
          });

          setRelatedEvents([...filteredFetchedEvents, ...filteredEvents]);
        } else setRelatedEvents([...filteredFetchedEvents, ...result]);
      } else {
        setRelatedEvents([...filteredFetchedEvents]);
      }
    };
    fetchRelatedEvents();
  }, []);

  const eventLongDescription = useMemo(() => {
    if (!event?.longDescription) return [];

    const parser = new DraftParser(
      EditorState.createWithContent(
        convertFromRaw(JSON.parse(event.longDescription))
      )
    );

    const jsx = ReactHtmlParser(parser.parse(), undefined);
    return jsx;
  }, [event]);

  return (
    <div className="company">
      <div className="company__container">
        <h1 className="company__title">{event?.title}</h1>
        <p className="company__p">
              <span className="company__span">Organisé par: </span>
              {event?.announcerName}
            </p>
        <div className="company__content">
          <img src={event?.imageUrl[0]} alt="" style={{ maxWidth: "100%" }} />
          <div className="company__info">
            <p className="company__p">
              {event &&
              event?.location.address &&
              event?.location.address.addressNumber ? (
                <>
                  <span className="company__span">Adresse: </span>
                  <span>
                    {event?.location.address.addressNumber}{" "}
                    {event?.location.address.street},{" "}
                    {event?.location.address.city},{" "}
                    {event?.location.address.province}
                  </span>
                </>
              ) : (
                ""
              )}
            </p>
            <p className="company__p">
              <span className="company__span">Début de l'événement: </span>
              {moment(
                new Date((event?.dates.startDate as any).seconds * 1000)
              ).format("dddd DD MMMM H:mm")}
            </p>
            <p className="company__p">
              <span className="company__span">Fin de l'événement: </span>
              {moment(
                new Date((event?.dates.endDate as any).seconds * 1000)
              ).format("dddd DD MMMM H:mm")}
            </p>
            <p className="company__p">
              <span className="company__span">Prix: </span>
              {event?.cost.payForEntry ? `${event.cost.price}$` : "Gratuit"}
            </p>
            <p className="company__p">
              <span className="company__span">Emplacement: </span>
              {event?.location.inPerson ? <span>{event.location.address?.addressNumber} {event.location.address?.street}, {event.location.address?.postalCode}, {event.location.address?.city}</span> : "En ligne"}
            </p>
            <p className="company__p">
              <span className="company__span">Public: </span>
              {event?.audience.Adolescents ? `Adolescents, ` : ""}
              {event?.audience.Adultes ? `Adultes, ` : ""}
              {event?.audience.Ainés ? `Ainés, ` : ""}
              {event?.audience.Autre ? `Autre, ` : ""}
              {event?.audience[`Enfants (6-12 ans)`] ? `Enfants (6-12 ans), ` : ""}
              {event?.audience[`Enfants d'âge préscolaire (moins de 5 ans)`] ? `Enfants d'âge préscolaire (moins de 5 ans), ` : ""}
              {event?.audience.Famille ? `Famille, ` : ""}
              {event?.audience.Tous ? `Tous` : ""}
            </p>
            <p className="company__p">
              <span className="company__span">Description: </span>
              {eventLongDescription[0] !== undefined &&
                eventLongDescription[0].props.children !== null && (
                  <p
                   className="company__longDesc"
                  >
                    {eventLongDescription.map(
                      (paragraph: any, index: number, self: any) => {
                        if (paragraph.props.children?.length === 0)
                          return (
                            <Fragment key={index}>
                              <br />
                            </Fragment>
                          );
                        if (self.length <= 10 && index === self.length - 1)
                          return <Fragment key={index}>{paragraph}</Fragment>;

                        if (index > 0 && index % 10 === 0)
                          return <Fragment key={index}>{paragraph}</Fragment>;

                        return <Fragment key={index}>{paragraph}</Fragment>;
                      }
                    )}
                  </p>
                )}
            </p>
            <p className="company__p">
              {event?.language.bilingual ? <span className="company__span">L'activité est bilingue (français/anglais)</span> : <span className="company__span">L'activité offerte est en français seulement</span>}
            </p>
            {event?.cost &&
              event?.cost.link !== "" &&
              event?.cost.link !== undefined &&
              event?.cost.text !== "" && (
                <Button variant="outlined" style={{ marginTop: "15px", marginBottom: "15px"}}>
                  <a
                    href={
                      event.cost.link.substring(0, 8) !== "https://"
                        ? "https://" + event.cost.link
                        : event.cost.link
                    }
                  >
                    {event.cost.text}
                  </a>
                </Button>
              )}

            {event?.location.inPerson && (
              <div id={event.id} style={{ width: "100%", height: "300px" }}>
                <>
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyDyQK6EzEgb0W8j0HSXSaICLBlvN9jZk-A",
                    }}
                    defaultCenter={{
                      lat: event!.location!.geoHash!.geopoint._lat,
                      lng: event!.location!.geoHash!.geopoint._long,
                    }}
                    defaultZoom={11}
                    yesIWantToUseGoogleMapApiInternals
                    options={
                      {
                        disableDefaultUI: true,
                        mapId: "b4689148d50a5238",
                      } as MapOptions
                    }
                  >
                    <VivreMarker
                      lat={event!.location!.geoHash!.geopoint._lat}
                      lng={event!.location!.geoHash!.geopoint._long}
                      name={event.title}
                      logo={[event.imageUrl[0]]}
                      status={
                        event.cost.payForEntry === true
                          ? `Payant: ${event.cost.price}$ `
                          : "Gratuit"
                      }
                      busid={event.id}
                    />
                  </GoogleMapReact>
                </>
              </div>
            )}
            <div style={{marginTop: 10}}>Étiquette{event && event?.tags.length > 1 ? "s" : ""} : {event?.tags.map((t:string, i:number) => <span>{t}, </span>)}</div>
            <div
              style={{
                marginTop: 10,
                width: "100%",
                gap: "10px",
              }}
              className="event__tooltip"
            >
              {event?.cost.payForEntry ? (
                <div className="event__tooltip--dollar">
                  <img
                    src={notFree}
                    alt=""
                    style={{ height: "30px", width: "30px" }}
                  />
                </div>
              ) : (
                <div className="event__tooltip--free">
                  <img
                    src={free}
                    alt=""
                    style={{ height: "30px", width: "30px" }}
                  />
                </div>
              )}
              {event?.location.inPerson ? (
                <div className="event__tooltip--presentiel">
                  <img
                    src={inPerson}
                    alt=""
                    style={{ height: "30px", width: "30px" }}
                  />
                </div>
              ) : (
                <div className="event__tooltip--virtual">
                  <img
                    src={virtual}
                    alt=""
                    style={{ height: "30px", width: "30px" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <h5 style={{marginTop: "25px", marginBottom: "25px", textAlign: "center"}}>Ces événements pourraient aussi vous intéresser:</h5>
      <EventsList events={relatedEvents} setEvents={setRelatedEvents} />
    </div>
  );
};

export default Event;
