import { SetStateAction, useEffect, useState, Dispatch } from "react";

// Interfaces
import { Vivre } from "franco-interfaces";

import useDb from "../hooks/useDb";

const useFetchPromotedVivres = (
  setLoading?: Dispatch<SetStateAction<boolean>>
) => {
  const [promotedVivres, setPromotedVivres] = useState<Vivre[]>([]);
  const getPromotedVivres = useDb<Vivre>("Vivre", null).getById;
  useEffect(() => {
    const fetchPromotedVivres = async () => {
      const vivresArray = [];
      const promotedVivreDocument = await getPromotedVivres("PromotedVivres");
      if (promotedVivreDocument.promotedVivres) {
        if (promotedVivreDocument.promotedVivres[0]) {
          vivresArray.push(
            await getPromotedVivres(promotedVivreDocument.promotedVivres[0])
          );
        }
        if (promotedVivreDocument.promotedVivres[1]) {
          vivresArray.push(
            await getPromotedVivres(promotedVivreDocument.promotedVivres[1])
          );
        }
      }
      setPromotedVivres(vivresArray);
      if (setLoading) setLoading(false);
    };
    fetchPromotedVivres();
  }, []);
  return { promotedVivres };
};

export default useFetchPromotedVivres;
