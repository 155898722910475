import serviceConfig from "./firebaseConfig.json";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import * as geofirex from "geofirex";

firebase.initializeApp(serviceConfig);

// Init GeoFireX
export const geo = geofirex.init(firebase as any);

export const auth = firebase.auth();
export const firestore = firebase.firestore;
export const db = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.functions();
