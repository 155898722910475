import { useRef } from "react";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
// Material UI
import { ThemeProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// Pages
import Routes from "./components/routes";
import Navbar from "./components/navbar";
import Footer from "./components/footer";

// assets
import Logo from "./assets/Logo.png";
import IOS from "./assets/app-store.png";
import Android from "./assets/google-play.png";

// Styles
import "./scss/App.scss";
// Theme
import theme from "./theme";
// Stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const App = () => {
  const stripePromise = loadStripe(
    "pk_live_51JzPcqIrx28T3SMbM00l9pJh0AmPbHcvWve8BkRSqJRD5z6rZqmfIe6yOe4baJyxTIbirOOTL0YCrbpiAcBKKwzY00imaFldYp"
  );
  const SnackbarProviderRef: any = useRef(null);

  return (
    <Elements stripe={stripePromise}>
      <SnackbarProvider
        hideIconVariant
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={4000}
        ref={SnackbarProviderRef}
        action={(key) => (
          <Button
            onClick={() => {
              const ref = SnackbarProviderRef.current;
              if (ref) {
                ref.closeSnackbar(key);
              }
            }}
          >
            FERMER
          </Button>
        )}
      >
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            {window.innerWidth < 850 ? (
              <div className="forceDownload">
                <img className="forceDownload__logo" src={Logo} />
                <p>
                  Téléchargez notre application mobile sur votre tablette ou
                  téléphone intelligent
                </p>
                <a href="https://apps.apple.com/us/app/frabio/id1599566866">
                  <img className="forceDownload__ios" src={IOS} />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.lefranco.frabio">
                  <img className="forceDownload__android" src={Android} />
                </a>
              </div>
            ) : (
              <>
                <Navbar />
                <Routes />
                <Footer />
              </>
            )}
          </BrowserRouter>
        </ThemeProvider>
      </SnackbarProvider>
    </Elements>
  );
};

export default App;
