import React, { Fragment, Dispatch, SetStateAction } from "react";
//Hooks
import { useHistory } from "react-router-dom";
//Interface
import { Vivre } from "franco-interfaces";
//Custom Components
import AlgoliaSearchBar from "../components/AlgoliaSearchBar";
//Mui
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";

const categoryArray = [
  "Soins et bien-être",
  "Service Immobiliers",
  "Éducation",
  "Sorties et expérience",
  "Organismes communautaires",
  "Services professionnels",
  "Autre",
  "Tout",
];

interface Props {
  algoliaSearchString: string;
  setAlgoliaSearchString: Dispatch<SetStateAction<string>>;
  setAlgoliaQueryResults: Dispatch<SetStateAction<Vivre[]>>;
  filteredVivres: Vivre[];
  filter: string;
  setFilter: Dispatch<SetStateAction<string>>;
  hitsPerPage: number;
  promoted?: any;
}

const CompanyList: React.FC<Props> = ({
  algoliaSearchString,
  setAlgoliaSearchString,
  setAlgoliaQueryResults,
  filteredVivres,
  filter,
  setFilter,
  hitsPerPage,
  promoted,
}) => {
  // Hooks
  const history = useHistory();

  return (
    <Paper className="companyList">
      <Stack className="companyList__stack" spacing={2} sx={{ marginTop: 2 }}>
        <AlgoliaSearchBar
          collection="Vivre"
          searchString={algoliaSearchString}
          setSearchString={setAlgoliaSearchString}
          setQueryResults={setAlgoliaQueryResults}
          hitsPerPage={hitsPerPage}
        />
        <FormControl fullWidth>
          <InputLabel id="test-select-label">Filtres</InputLabel>
          <Select
            id="demo-simple-select"
            labelId="test-select-label"
            label="Filter"
            value={filter}
            onChange={(event) => {
              setFilter(event.target.value);
            }}
          >
            {categoryArray.map((filter: any, i) => {
              return (
                <MenuItem value={filter} key={i}>
                  {filter}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Stack>
      <List className="companyList__container">
        {filteredVivres.map((vivre, i) => {
          return (
            <Fragment key={i}>
              <div
                className={
                  promoted && promoted.includes(vivre.id)
                    ? "companyList__list companyList__promoted"
                    : "companyList__list"
                }
                onClick={() =>
                  history.push(
                    `/vivre/${filteredVivres[i].id}`,
                    filteredVivres[i]
                  )
                }
              >
                <img
                  className="companyList__list__logo"
                  src={vivre.logo[0]}
                  alt=""
                />
                <div className="companyList__list__info">
                  <p className="companyList__list__category">
                    {vivre.category}
                  </p>
                  <h6 className="companyList__list__name">{vivre.name}</h6>
                  <p className="companyList__list__address">
                    {vivre.address.city}
                  </p>
                </div>
              </div>
              <Divider />
            </Fragment>
          );
        })}
      </List>
    </Paper>
  );
};

export default CompanyList;
