import {
  Dispatch,
  SetStateAction,
  useState,
  useImperativeHandle,
  forwardRef,
  Ref,
} from "react";
import { useSnackbar } from "notistack";
// Hooks
import { geo } from "../firebase/firebase";
// Interfaces
import { Vivre as VivreInterface } from "franco-interfaces";
// Custom components
import Title from "./title";
import ImageUploader from "./imagesUploader";
import { emailRegex, pattern } from "../utils/regex";
// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// Mui
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const requiredMessage = "Ce champ est requis";
const numberMessage = "Doit être un nombre";
const frenchLevel = ["1", "2", "3", "4"];

const websiteOptions = [
  { name: "website", label: "Site Web" },
  { name: "facebook", label: "Facebook" },
  { name: "twitter", label: "Twitter" },
  { name: "instagram", label: "Instagram" },
  { name: "linkedIn", label: "LinkedIn" },
];

const addressOptions: any = [
  { name: "city", label: "Ville" },
  { name: "street", label: "Rue" },
  { name: "country", label: "Pays" },
  { name: "postalCode", label: "Code Postal" },
  { name: "addressNumber", label: "Numéro Civique" },
];
const dayOptions = [
  { name: "monday", label: "Lundi" },
  { name: "tuesday", label: "Mardi" },
  { name: "wednesday", label: "Mercredi" },
  { name: "thursday", label: "Jeudi" },
  { name: "friday", label: "Vendredi" },
  { name: "saturday", label: "Samedi" },
  { name: "sunday", label: "Dimanche" },
];
const categoryOptions = [
  "Soins et bien-être",
  "Services immobiliers",
  "Éducation",
  "Sorties et expérience",
  "Organismes communautaires",
  "Services professionnels",
  "Autre",
];

const provinceOptions = [
  "Alberta",
  "Colombie-Britannique",
  "Île-du-Prince-Édouard",
  "Manitoba",
  "Nouveau-Brunswick",
  "Nouvelle-Écosse",
  "Ontario",
  "Québec",
  "Saskatchewan",
  "Terre-Neuve-et-Labrador",
  "Nunavut",
  "Territoires du Nord-Ouest",
  "Yukon",
];

const vivreSchema = yup.object({
  name: yup.string().required(requiredMessage),
  email: yup
    .string()
    .matches(emailRegex, "Courriel invalide")
    .required(requiredMessage),
  french: yup.string(),
  frenchExplanation: yup.string(),
  description: yup.string().required(requiredMessage),
  category: yup.string(),
  phone: yup.string(),
  physical: yup.number(),
  existingHours: yup.number(),
  // Address
  city: yup.string().when("physical", {
    is: 1,
    then: yup.string().required(requiredMessage),
  }),
  street: yup.string().when("physical", {
    is: 1,
    then: yup.string().required(requiredMessage),
  }),
  addressNumber: yup.string().when("physical", {
    is: 1,
    then: yup.string().required(requiredMessage).typeError(numberMessage),
  }),
  postalCode: yup.string().when("physical", {
    is: 1,
    then: yup.string().required(requiredMessage),
  }),
  country: yup.string().when("physical", {
    is: 1,
    then: yup.string().required(requiredMessage),
  }),
  province: yup.string().when("physical", {
    is: 1,
    then: yup.string().required(requiredMessage),
  }),

  // Websites
  website: yup.string().matches(pattern, "Lien Invalide"),
  facebook: yup.string().matches(pattern, "Lien Invalide"),
  twitter: yup.string().matches(pattern, "Lien Invalide"),
  instagram: yup.string().matches(pattern, "Lien Invalide"),
  linkedIn: yup.string().matches(pattern, "Lien Invalide"),
  // Days
  monday: yup.string().when("existingHours", {
    is: 1,
    then: yup.string().required(requiredMessage),
  }),
  tuesday: yup.string().when("existingHours", {
    is: 1,
    then: yup.string().required(requiredMessage),
  }),
  wednesday: yup.string().when("existingHours", {
    is: 1,
    then: yup.string().required(requiredMessage),
  }),
  thursday: yup.string().when("existingHours", {
    is: 1,
    then: yup.string().required(requiredMessage),
  }),
  friday: yup.string().when("existingHours", {
    is: 1,
    then: yup.string().required(requiredMessage),
  }),
  saturday: yup.string().when("existingHours", {
    is: 1,
    then: yup.string().required(requiredMessage),
  }),
  sunday: yup.string().when("existingHours", {
    is: 1,
    then: yup.string().required(requiredMessage),
  }),
});

// HELPER FUNCTIONS
const companyHasHours = (companyInfo: VivreInterface): boolean => {
  for (const day in companyInfo.hours) {
    if (companyInfo.hours[day].length > 0) return true;
  }
  return false;
};

const verifyLinks = (link: string): string => {
  if (
    link.length > 0 &&
    link.substring(0, 8) !== "https://" &&
    link.substring(0, 7) !== "http://" &&
    link.length > 0
  )
    return "https://" + link;
  else return link;
};

interface Props {
  companyInfo: VivreInterface;
  setCompanyInfo: Dispatch<SetStateAction<VivreInterface>>;
}

const CompanyInfo = ({ companyInfo, setCompanyInfo }: Props, ref: Ref<any>) => {
  // Hooks
  const { enqueueSnackbar } = useSnackbar();
  // States
  const [vivre, setVivre] = useState<VivreInterface | null>(null);
  const [frenchValue, setFrenchValue] = useState<string>("");
  const [physical, setPhysical] = useState(companyInfo.physical);
  const [existingHours, setExistingHours] = useState(
    companyHasHours(companyInfo)
  );
  const defaultValues = {
    //images
    logo: companyInfo.logo ?? "",
    imageUrl: companyInfo.imageUrl ?? "",
    name: companyInfo.name ?? "",
    french: companyInfo.french ?? "",
    frenchExplanation: companyInfo.frenchExplanation ?? "",
    description: companyInfo.description ?? "",
    email: companyInfo.email ?? "",
    phone: companyInfo.phone ?? "",
    category: companyInfo.category ?? "",
    physical: companyInfo.physical ? 1 : 0,
    existingHours: companyInfo.existingHours ? 1 : 0,
    // Websites
    website: companyInfo.website ?? "",
    facebook: companyInfo.facebook ?? "",
    twitter: companyInfo.twitter ?? "",
    instagram: companyInfo.instagram ?? "",
    linkedIn: companyInfo.linkedIn ?? "",
    // Address
    city: companyInfo.address.city ?? "",
    street: companyInfo.address.street ?? "",
    addressNumber: companyInfo.address.addressNumber ?? "",
    postalCode: companyInfo.address.postalCode ?? "",
    country: companyInfo.address.country ?? "",
    province: companyInfo.address.province ?? "",
    // Days
    monday: companyInfo.hours.monday ?? "",
    tuesday: companyInfo.hours.tuesday ?? "",
    wednesday: companyInfo.hours.wednesday ?? "",
    thursday: companyInfo.hours.thursday ?? "",
    friday: companyInfo.hours.friday ?? "",
    saturday: companyInfo.hours.saturday ?? "",
    sunday: companyInfo.hours.sunday ?? "",
  };
  const {
    handleSubmit,
    formState: { errors, isValid, isSubmitSuccessful },
    control,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(vivreSchema),
    defaultValues,
    mode: "onChange",
  });
  // Exposes values and functions so Form can call and verify in proper order
  useImperativeHandle(ref, () => ({
    handleSubmit: handleSubmit(onSubmit),
    isFormValid: isValid,
    isSubmitSuccessful: isSubmitSuccessful,
  }));

  const fetchCoordinates = async (stringAddress: string) => {
    let geoHash: any = {};
    const geocoder: any = new google.maps.Geocoder();
    await geocoder.geocode(
      { address: stringAddress },
      function (results: any, status: any) {
        if (status === "OK") {
          const lat = results[0].geometry.location.lat();
          const lng = results[0].geometry.location.lng();
          geoHash = geo.point(lat, lng);
        } else {
          enqueueSnackbar("La location de l'adresse inscrit n'est pas valide", {
            variant: "error",
          });
        }
      }
    );
    return geoHash;
  };

  const onSubmit = async (data: any) => {
    try {
      if (!vivre?.logo) {
        enqueueSnackbar("SVP veuillez ajouter une image pour votre logo", {
          variant: "error",
        });
        setError("imageUrl", {
          message: "L'image est manquante!",
        });
        return;
      }
      const dataWithoutAddressAndHours = {
        imageUrl: vivre?.imageUrl ?? [],
        logo: vivre?.logo ?? [],
        name: data.name,
        email: data.email,
        status: "En Attente",
        french: data.french,
        frenchExplanation: data.frenchExplanation,
        description: data.description,
        phone: data.phone,
        category: data.category,
        website: verifyLinks(data.website),
        facebook: verifyLinks(data.facebook),
        twitter: verifyLinks(data.twitter),
        instagram: verifyLinks(data.instagram),
        linkedIn: verifyLinks(data.linkedIn),
        existingHours: data.existingHours ? true : false,
        physical: data.physical ? true : false,
      };

      const address = {
        city: data.city,
        country: data.country,
        addressNumber: data.addressNumber,
        street: data.street,
        province: data.province,
        postalCode: data.postalCode,
      };

      const hours = {
        monday: data.monday,
        tuesday: data.tuesday,
        wednesday: data.wednesday,
        thursday: data.thursday,
        friday: data.friday,
        saturday: data.saturday,
        sunday: data.sunday,
      };

      let geoHash: any = {};
      if (physical) {
        geoHash = await fetchCoordinates(
          `${address.addressNumber} ${address.street}, ${address.city}, ${address.province}`
        );
      }

      const randomNumber = Math.floor(Math.random() * 100000) + 1;
      setCompanyInfo({
        ...dataWithoutAddressAndHours,
        address,
        hours,
        random: randomNumber,
        supporters: 0,
        geoHash,
        subscriptionId: "",
      });
    } catch (e: any) {
      if (e.response)
        enqueueSnackbar(e.response.data.message, { variant: "error" });
    }
  };

  const properExplanation = (level:any) => {
    if(level === "1") {
     return "Appuie la francophonie, mais ne garantit aucun services en français"
    }else if(level === "2") {
      return "Documentation disponible en français"
    }else if(level === "3") {
      return `Services en français offerts à l'occasion ou sur demande`
    }else {
      return "Services en français en tout temps"
    }
  }

  return (
    <>
      <Title title="Vivre" />
      <div>
        <h3 className="vivre__text">Images</h3>
        <h5 className="vivre__text">Logo</h5>
        <ImageUploader
          storagePath="Vivres"
          borderRadius="0px"
          imageUrls={vivre?.logo ?? []}
          onChange={(logo: string[]) => {
            setVivre(
              (prev: VivreInterface | null) =>
                ({ ...prev, logo } as VivreInterface)
            );
          }}
        />
        <h5 className="vivre__text">Toutes les images du service</h5>
        <ImageUploader
          storagePath="Vivres"
          borderRadius="0px"
          multipleImages
          imageUrls={vivre?.imageUrl ?? []}
          onChange={(imageUrl: string[]) => {
            setVivre(
              (prev: VivreInterface | null) =>
                ({ ...prev, imageUrl } as VivreInterface)
            );
          }}
        />
      </div>
      <h2 className="vivre__title">Information</h2>
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            error={!!errors.name}
            helperText={errors.name?.message}
            fullWidth
            label="Nom"
            {...field}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <TextField
            error={!!errors.description}
            helperText={errors.description?.message}
            fullWidth
            label="Description"
            {...field}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            error={!!errors.email}
            helperText={errors.email?.message}
            fullWidth
            label="Courriel"
            {...field}
          />
        )}
      />
      <Controller
        name="phone"
        control={control}
        render={({ field }) => (
          <TextField
            error={!!errors.phone}
            helperText={errors.phone?.message}
            fullWidth
            label="Numéro de téléphone"
            {...field}
          />
        )}
      />
      <Controller
        render={({ field }) => (
          <FormControl fullWidth>
            <InputLabel id="french-id">Niveau de français</InputLabel>
            <Select
              color="secondary"
              labelId="french-id"
              error={!!errors.french}
              {...field}
            >
              {frenchLevel.map((r, i) => (
                <MenuItem
                  key={i}
                  value={r}
                  onClick={() => {
                    setFrenchValue(r);
                  }}
                >
                  {r} - {properExplanation(r)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        name="french"
        control={control}
      />
      {(frenchValue === "3" || frenchValue === "4") && (
        <Controller
          name="frenchExplanation"
          control={control}
          render={({ field }) => (
            <TextField
              error={!!errors.frenchExplanation}
              helperText={errors.frenchExplanation?.message}
              fullWidth
              label="Explication du niveau de Français"
              {...field}
            />
          )}
        />
      )}
      <br />
      <br />
      <Controller
        render={({ field }) => (
          <FormControl fullWidth>
            <InputLabel id="categories-id">Catégorie</InputLabel>
            <Select
              color="secondary"
              labelId="categories-id"
              error={!!errors.category}
              {...field}
            >
              {categoryOptions.map((r, i) => (
                <MenuItem key={i} value={r}>
                  {r}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        name="category"
        control={control}
      />
      <h2 className="vivre__title">Liens</h2>
      {websiteOptions.map((option: any, i: number) => (
        <Controller
          key={i}
          name={option.name}
          control={control}
          render={({ field }) => (
            <TextField
              error={!!(errors as any)[option.name]}
              helperText={(errors as any)[option.name]?.message}
              fullWidth
              label={option.label}
              {...field}
            />
          )}
        />
      ))}
      <h2 className="vivre__title">Adresse</h2>
      <Controller
        render={({ field }) => (
          <FormControl fullWidth>
            <InputLabel id="notPhysical-id">Adresse Physique</InputLabel>
            <Select
              {...field}
              color="secondary"
              labelId="notPhysical-id"
              value={physical ? 1 : 0}
              onChange={(e: any) => {
                setPhysical(e.target.value ? true : false);
                setValue("physical", e.target.value);
              }}
            >
              <MenuItem value={0}>Non</MenuItem>
              <MenuItem value={1}>Oui</MenuItem>
            </Select>
          </FormControl>
        )}
        name="physical"
        control={control}
      />
      {(companyInfo.physical || physical) && (
        <>
          {addressOptions.map((option: any, i: number) => (
            <Controller
              key={i}
              name={option.name}
              control={control}
              render={({ field }) => (
                <TextField
                  error={!!(errors as any)[option.name]}
                  helperText={(errors as any)[option.name]?.message}
                  fullWidth
                  label={option.label}
                  {...field}
                />
              )}
            />
          ))}
          <Controller
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="province-id">Province</InputLabel>
                <Select
                  color="secondary"
                  labelId="province-id"
                  error={!!errors.province}
                  {...field}
                >
                  {provinceOptions.map((r, i) => (
                    <MenuItem key={i} value={r}>
                      {r}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            name="province"
            control={control}
          />
        </>
      )}
      <h2 className="vivre__title">Heures</h2>
      <Controller
        render={({ field }) => (
          <FormControl fullWidth>
            <InputLabel id="days-id">Heures d'ouvertures</InputLabel>
            <Select
              {...field}
              color="secondary"
              labelId="days-id"
              value={existingHours ? 1 : 0}
              onChange={(e: any) => {
                setExistingHours(e.target.value ? true : false);
                setValue("existingHours", e.target.value);
              }}
            >
              <MenuItem value={0}>Non</MenuItem>
              <MenuItem value={1}>Oui</MenuItem>
            </Select>
          </FormControl>
        )}
        name="existingHours"
        control={control}
      />
      {existingHours &&
        dayOptions.map((option: any, i: number) => (
          <Controller
            key={i}
            name={option.name}
            control={control}
            render={({ field }) => (
              <TextField
                error={!!(errors as any)[option.name]}
                helperText={(errors as any)[option.name]?.message}
                fullWidth
                label={option.label}
                {...field}
              />
            )}
          />
        ))}
    </>
  );
};

export default forwardRef(CompanyInfo);
