export const blocktypeRenderer: { [index: string]: Function } = {
  unstyled: (content: string) => {
    if (content === "") return "<br/>";
    return `<div>${content}</div>`;
  },
  "header-one": (content: string) => `<h1>${content}</h1>`,
  "header-two": (content: string) => `<h2>${content}</h2>`,
  "header-three": (content: string) => `<h3>${content}</h3>`,
  "header-four": (content: string) => `<h4>${content}</h4>`,
  "header-five": (content: string) => `<h5>${content}</h5>`,
  "header-six": (content: string) => `<h6>${content}</h6>`,
  "unordered-list-item": (items: string[]) =>
    `<ul>${items.map((item: string) => `<li>${item}</li>`).join("")}</ul>`,
  "ordered-list-item": (items: string[]) =>
    `<ol>${items.map((item: string) => `<li>${item}</li>`).join("")}</ol>`,
  blockquote: (content: string) => `<blockquote>${content}</blockquote>`,
  code: (content: string) => `<pre>${content}</pre>`,
  color_box: ({ text, color }: any) =>
    `<div style="background-color:${color};padding: 10px;outline: 1px solid black;border-radius:2px">${text}</div>`,
  align_left: (content: string) =>
    `<div style="text-align:left">${content}</div>`,
  align_center: (content: string) =>
    `<div style="text-align:center">${content}</div>`,
  align_right: (content: string) =>
    `<div style="text-align:right">${content}</div>`,
  highlight: (content: string) =>
    `<div style="background-color:#f9f9f9;border-left:3px solid #2080bb;border-radius:2px;margin:1.5em 10px;padding:31px 30px 31px 30px">${content}</div>`,
  IMAGE: ({ src, caption, width, height, alignment }: any) => {
    width = width.includes("px") ? width.split("px")[0] : width;
    height = height.includes("px") ? height.split("px")[0] : width;

    return `<figure style="display: flex; flex-direction: column;float: ${alignment};align-items: center">
             <img src="${src}" alt="${
      caption ?? ""
    }" height="${height}" width="${width}"/>
             ${caption && `<figcaption>${caption}</figcaption>`}
         </figure>`;
  },
  TWITTER: ({ tweetId }: any) => `tweetId=${tweetId}`,
  FACEBOOK: ({ facebookPost }: any) => `
          <iframe 
                            src="https://www.facebook.com/plugins/post.php?href=${encodeURIComponent(
                              facebookPost
                            )}&show_text=true&width=500"
                            width="500"
                            height="483"
                            style="border:none;overflow:hidden"
                            scrolling="no"
                            frameborder="0"
                            allowfullscreen="true"
                            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
    `,
};

export const inlineStyleRenderer: {
  [index: string]: (text: string) => string;
} = {
  BOLD: (text) => `<strong>${text}</strong>`,
  ITALIC: (text) => `<em>${text}</em>`,
  UNDERLINE: (text) => `<ins>${text}</ins>`,
  STRIKETHROUGH: (text) => `<del>${text}</del>`,
  CODE: (text) => `<code>${text}</code>`,
  LINK: ({ text, url }: any) =>
    `<a href=${
      url.substring(0, 8) !== "https://" ? "https://" + url : url
    } target="_blank" rel="noreferrer">${text}</a>`,
};
