import MuiAvatar from "@material-ui/core/Avatar";
interface AvatarProps {
    borderRadius?: string;
    width?: string;
    height?: string;
    src: string;
    onClick?: () => void;
    cover?: boolean;
}
const Avatar = ({ cover = true, borderRadius = "50", width = "250", height = "250", src, onClick }: AvatarProps) => {
    return (
        <MuiAvatar
            onClick={onClick}
            className={`avatar__img ${cover ? 'avatar__cover' : 'avatar__contain'} `}
            style={{ width, height, borderRadius}}
            src={src}
        />
    );
};

export default Avatar;