// Firebase
import { db } from "./firebase";
import {
  QueryDocumentSnapshot,
  QuerySnapshot,
  DocumentSnapshot,
  WhereFilterOp,
  OrderByDirection,
} from "@firebase/firestore-types";

// Interfaces
import { Base } from "franco-interfaces";

// Settings
import settings from "../settings.json";

export interface Filter {
  field: string;
  operator: WhereFilterOp;
  value: any | any[];
}

export interface OrderBy {
  field: string;
  direction: OrderByDirection;
}

// this function is curried in order to pass a type, i.e. T, as a parameter.
// it is used to format the data receive from firestore into whatever we need.
//
// For exemple, firestore sends dates as { seconds, nanoseconds }. We can
// format them to an actual date.
export const genDoc: <T extends Base>() => (doc: QueryDocumentSnapshot) => T =
  <T>() =>
  (doc: QueryDocumentSnapshot) => {
    const item = doc.data();
    if (item) {
      item.createdAt = new Date(item.createdAt.seconds * 1000);
      item.updatedAt = new Date(item.updatedAt.seconds * 1000);
    }
    return item as T;
  };

// If the result of this call is an empty array there are a few possible outcomes.
// Since firestore does not throw an error when the collection DOES NOT exists,
// make sure that the collection name is accurate. The list may also be empty if
// there are not documents in the collection or if all the documents in the collection
// have their 'isDeleted' flag set to true
//
// TODO: Firestore requires orderBy to be the same as the where clause. Since orderBy is
// by default 'createdAt', it might cause some crashes. We'll see what happens...
export async function getAll<T extends Base>(
  collection: string,
  filters: Filter[] = [],
  orderBy: OrderBy[] = [{ field: "createdAt", direction: "desc" }],
  isDeleted = false,
  allData = false,
  hasLimit = false,
  limitNum: number,
  startAfter: any = undefined
): Promise<T[]> {
  const [firstOrderBy, ...restOrderBy] = orderBy;
  let query = db
    .collection(collection)
    .orderBy(firstOrderBy.field, firstOrderBy.direction);

  for (const ob of restOrderBy) query = query.orderBy(ob.field, ob.direction);

  let startAfterDoc = null;
  if (startAfter) {
    startAfterDoc = await db.collection(collection).doc(startAfter.id).get();
  }

  if (allData) {
    if (startAfter) {
      query = db
        .collection(collection)
        .orderBy(orderBy[0].field, orderBy[0].direction)
        .startAfter(startAfterDoc);
    } else
      query = db
        .collection(collection)
        .orderBy(orderBy[0].field, orderBy[0].direction);
  } else {
    if (startAfter) {
      query = db
        .collection(collection)
        .where("isDeleted", "==", isDeleted)
        .orderBy(orderBy[0].field, orderBy[0].direction)
        .startAfter(startAfterDoc);
    } else
      query = db
        .collection(collection)
        .where("isDeleted", "==", isDeleted)
        .orderBy(orderBy[0].field, orderBy[0].direction);
  }

  if (hasLimit)
    query = query.limit(limitNum !== 0 ? limitNum : settings.page.rowsPerPage);
  let data = await query.get();

  if (filters.length === 0) return data.docs.map(genDoc<T>());

  // apply filters
  const firstFilter: Filter = filters[0];
  query = query.where(
    firstFilter.field,
    firstFilter.operator,
    firstFilter.value
  );

  // apply rest of filters
  for (const f of filters.slice(1, filters.length)) {
    query = query.where(f.field, f.operator, f.value);
  }

  data = await query.get();
  return data.docs.map(genDoc<T>());
}

export async function getById<T extends Base>(
  collection: string,
  id: string
): Promise<T> {
  const doc = await db.collection(collection).doc(id).get();

  if (!doc.exists)
    throw Error(`Cannot find doc with id ${id} in ${collection}`);
  return genDoc<T>()(doc as QueryDocumentSnapshot);
}

export async function create<T extends Base>(
  collection: string,
  data: T,
  id?: string
): Promise<T> {
  if (!("isDeleted" in data)) data["isDeleted"] = false;
  if (!("createdAt" in data)) data["createdAt"] = new Date();
  if (!("updatedAt" in data)) data["updatedAt"] = new Date();

  let query = db.collection(collection);
  if (id) {
    await query.doc(id).set(data);
    return getById(collection, id);
  }

  const docRef = query.doc();
  if (!docRef.id) throw Error(`Could not create doc in ${collection}: ${data}`);
  data["id"] = docRef.id;
  await docRef.set(data);
  return getById(collection, docRef.id);
}

export async function firstFetch(
  collection: string,
  callback: Function,
  acceptDeleted = false
) {
  onSnapshot(
    collection,
    callback,
    [{ field: "createdAt", direction: "desc" }],
    [],
    undefined,
    acceptDeleted,
    undefined,
    undefined,
    true
  );
}

export async function fetchNextPage<T extends Base>(
  collection: string,
  callback: Function,
  item: T,
  acceptDeleted = false
) {
  onSnapshot(
    collection,
    callback,
    [{ field: "createdAt", direction: "desc" }],
    [],
    undefined,
    acceptDeleted,
    item.createdAt
  );
}

export async function fetchLastPage<T extends Base>(
  collection: string,
  callback: Function,
  item: T,
  acceptDeleted = false
) {
  onSnapshot(
    collection,
    callback,
    [{ field: "createdAt", direction: "desc" }],
    [],
    undefined,
    acceptDeleted,
    undefined,
    item.createdAt
  );
}

export async function update<T extends Base>(
  collection: string,
  doc: T
): Promise<T> {
  if (!doc.id) throw Error("Requests: id must be defined");
  await db
    .collection(collection)
    .doc(doc.id)
    .update({ ...doc, updatedAt: new Date() });

  return getById(collection, doc.id);
}

export async function deleteById(
  collection: string,
  id: string,
  hard = false
): Promise<boolean> {
  try {
    let query = db.collection(collection).doc(id);

    hard ? await query.delete() : await query.update({ isDeleted: true });

    return true;
  } catch (e: any) {
    console.error(e);
    return false;
  }
}

export function onSnapshot<T extends Base>(
  collection: string,
  callback: Function,
  orderBy: OrderBy[] = [{ field: "createdAt", direction: "desc" }],
  filters: Filter[] = [],
  id?: string,
  acceptDeleted = false,
  startAfter?: any,
  endBefore?: any,
  hasLimit = false
) {
  if (id && filters.length)
    throw Error(
      "useDb: Error occured in onSnapshot. Filters cannot be combined with id"
    );
  if (id) {
    return db
      .collection(collection)
      .doc(id)
      .onSnapshot((doc: DocumentSnapshot) => {
        if (!doc.exists) callback(null);
        const data = genDoc<T>()(doc as QueryDocumentSnapshot);

        if (!acceptDeleted && data?.isDeleted) callback(null);
        callback(data);
      });
  }

  const [firstOrderBy, ...restOrderBy] = orderBy;

  let query = db
    .collection(collection)
    .orderBy(firstOrderBy.field, firstOrderBy.direction);

  for (const ob of restOrderBy) query = query.orderBy(ob.field, ob.direction);

  if (startAfter)
    query = query.startAfter(startAfter).limit(settings.page.rowsPerPage);
  if (endBefore)
    query = query.endBefore(endBefore).limitToLast(settings.page.rowsPerPage);

  if (hasLimit) query = query.limit(settings.page.rowsPerPage);

  if (filters.length) {
    // apply filters
    for (const filter of filters) {
      query = query.where(filter.field, filter.operator, filter.value);
    }
  }
  return query.onSnapshot((doc: QuerySnapshot) => {
    const data: T[] = doc.docs.map(genDoc<T>()).filter((item: T) => {
      if (acceptDeleted) return true;
      return !item.isDeleted;
    });
    callback(data);
  });
}

export function onSnapshotWithDoc<T extends Base>(
  document: string,
  callback: Function
) {
  return db.doc(document).onSnapshot((doc: DocumentSnapshot) => {
    if (!doc.exists) callback(null);
    const data = doc.data() as T;
    callback(data);
  });
}

export default { genDoc, getAll, getById, create, update, deleteById };
