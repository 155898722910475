import React, { useState, useRef, useEffect } from "react";

// Hooks
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

// MUI
import Stepper from "@material-ui/core/Stepper";
import StepLabel from "@mui/material/StepLabel";
import Step from "@mui/material/Step";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// Firebase
import { auth } from "../firebase/firebase";

// Custom Components
import Title from "../components/title";
import { useFetchUser } from "../hooks/useFetchUser";
import Login from "../components/Login";
import AnnouncerInfo from "../components/AnnouncerInfo";
import Checkout from "../components/Checkout";

const steps = ["Courriel", "Information du service", "Paiement"];

const AnnouncerForm: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { currentUser } = useFetchUser();
  //form info states
  const [customer, setCustomer] = useState<any>();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [plan, setPlan] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState(
    "1 événement par année - 79$"
  );
  const [quota, setQuota] = useState(1);

  const checkoutRef = useRef<any>(null);
  const loginRef = useRef<any>(null);

  //functions

  const handleNext = async () => {
    setLoading(true);
    switch (activeStep) {
      case 0: {
        const moveToNextStep = await loginRef.current.handleSubmit();
        if (moveToNextStep) setActiveStep(1);
        break;
      }
      case 1: {
        setActiveStep(2);
        break;
      }
      case 2: {
        await checkoutRef.current.handleSubmit();
        const moveToNextStep = checkoutRef.current.isSubmitSuccessful;
        if (moveToNextStep) {
          enqueueSnackbar("Votre application a été soumise", {
            variant: "success",
          });
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          window.location.href = "https://admin.frab.io/";
        } else {
          enqueueSnackbar("Une erreur s'est produite", {
            variant: "error",
          });
        }
        break;
      }
    }
    setLoading(false);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      auth.signOut();
      history.push("/");
      return;
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (
    <div className="form">
      <form className="form__container">
        <>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step className="form__step" key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <br />
          {activeStep === 0 && (
            <Login
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              setUser={setCustomer}
              ref={loginRef}
            />
          )}
          {activeStep === 1 && (
            <>
              <Title title="Votre mode de payment" />
              <AnnouncerInfo
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                plan={plan}
                setPlan={setPlan}
                setSubscriptionPlan={setSubscriptionPlan}
                setQuota={setQuota}
              />
            </>
          )}
          {activeStep === 2 && (
            <Checkout
              customer={customer}
              product={{
                ...currentUser,
                quota,
                subscriptionPlan,
                firstName,
                lastName,
              }}
              ref={checkoutRef}
            />
          )}
          <>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                className="form__button--back "
                variant="outlined"
                color="inherit"
                disabled={loading}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Retour
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                className="form__button--next "
                variant="contained"
                color="inherit"
                disabled={loading}
                onClick={handleNext}
              >
                {activeStep === steps.length - 1
                  ? "S'abonner"
                  : activeStep < steps.length && "Suivant"}
              </Button>
            </Box>
          </>
        </>
      </form>
    </div>
  );
};

export default AnnouncerForm;
