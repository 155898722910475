import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

//Context
import { Context } from "../appContext";

//Calendar
import FullCalendar, { DateSelectArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";

//icons
import { BiChevronRight } from "react-icons/bi";

//utils
import { formatYearDate } from "../utils/date";
import moment from "moment";

//Interface
import { Event as EventInterface } from "franco-interfaces";
import useDb from "../hooks/useDb";

//assets
import free from "../assets/free.svg";
import inPerson from "../assets/inPerson.svg";
import notFree from "../assets/dollarSignIcon.svg";
import virtual from "../assets/virtual.svg";

interface Props {
  events: EventInterface[];
}

const EventsCalendar: React.FC<Props> = ({ events }) => {
  const { findTagColor } = useContext(Context);
  const hist = useHistory();

  const [calendarEvents, setCalendarEvents] = useState<EventInterface[]>([]);

  const getAllEvents = useDb<EventInterface>("Events", null).getAll;

  const handleDateClick = (e: DateSelectArg) => {
    const fetchEvents = async () => {
      const specificDayEvents: EventInterface[] = await getAllEvents({
        orderBy: [{ field: "dates.releaseDate", direction: "desc" }],
        filters: [
          {
            field: "dates.releaseDate",
            operator: "<=",
            value: new Date(),
          },
          {
            field: "online",
            operator: "==",
            value: true,
          },
          {
            field: "dates.startDateString",
            operator: "==",
            value: e.startStr,
          },
          {
            field: "cancelled",
            operator: "==",
            value: false,
          },
        ],
      });
      const sorted = specificDayEvents.sort((a: any, b: any) => {
        return b.dates.startDate - a.dates.startDate;
      });
      setCalendarEvents([...sorted]);
    };
    fetchEvents();
  };

  useEffect(() => {
    const fetchEvents = async () => {
      const allEvents: EventInterface[] = await getAllEvents({
        orderBy: [{ field: "dates.releaseDate", direction: "desc" }],
        filters: [
          {
            field: "dates.releaseDate",
            operator: "<=",
            value: new Date(),
          },
          {
            field: "online",
            operator: "==",
            value: true,
          },
          {
            field: "dates.startDateString",
            operator: "==",
            value: formatYearDate(new Date()),
          },
          {
            field: "cancelled",
            operator: "==",
            value: false,
          },
        ],
      });
      const sortedAllEvents = allEvents.sort((a: any, b: any) => {
        return b.dates.startDate - a.dates.startDate;
      });
      setCalendarEvents([...sortedAllEvents]);
    };
    fetchEvents();
  }, []);

  return (
    <div className="eventsCalendar__container">
      <FullCalendar
        events={events.map((event: any, i) => {
          const start = new Date(event.dates.startDate.seconds * 1000);

          return {
            title: event.title,
            id: event.id,
            start: formatYearDate(start),
            color: findTagColor(event.tags[0]),
          };
        })}
        headerToolbar={{
          end: "prev,next",
        }}
        plugins={[dayGridPlugin, interactionPlugin]}
        select={(e) => {
          handleDateClick(e);
        }}
        selectable={true}
        selectLongPressDelay={0.1}
        locale="fr"
      />
      <div className="events__container">
        {calendarEvents.map((e: any, i: number) => (
          <article
          className={
            Date.now() > e.dates.endDate.seconds * 1000
              ? "card events__finished"
              : "card"
          }
          key={i}
        >
          <img
            onClick={() => {
              hist.push(`/calendrier/${e.id}`, e);
            }}
            src={e.imageUrl[0]}
            className="card__header"
          ></img>
          <p style={{ backgroundColor: findTagColor(e.tags[0])}} className="card__header__tag">{e.tags[0]}</p>
          <div className="card__body">
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <p className="card__startDate">{moment(new Date(e.dates.startDate.seconds * 1000)).format("DD/MM/YYYY")}</p>
              <div
              style={{
                display: "flex",
                gap: "10px",
                height: "30px",
              }}
              className="event__tooltip"
            >
              {e?.cost.payForEntry ? (
                <div className="event__tooltip--dollar">
                  <img
                    src={notFree}
                    alt=""
                    style={{ height: "30px", width: "30px" }}
                  />
                </div>
              ) : (
                <div className="event__tooltip--free">
                  <img
                    src={free}
                    alt=""
                    style={{ height: "30px", width: "30px" }}
                  />
                </div>
              )}
              {e?.location.inPerson ? (
                <div className="event__tooltip--presentiel">
                  <img
                    src={inPerson}
                    alt=""
                    style={{ height: "30px", width: "30px" }}
                  />
                </div>
              ) : (
                <div className="event__tooltip--virtual">
                  <img
                    src={virtual}
                    alt=""
                    style={{ height: "30px", width: "30px" }}
                  />
                </div>
              )}
            </div>
            </div>
            <h4 className="card__title">
              {e.title}
            </h4>
            <p
              style={{
                paddingBottom: "40px",
                fontSize: "13px",
                lineHeight: "1.8",
              }}
            >
              {e.shortDescription}
            </p>
            <button
              onClick={() => {
                hist.push(`/calendrier/${e.id}`, e);
              }}
              className="card__button"
            >
              <BiChevronRight /> Plus d'info
            </button>
          </div>
        </article>
        ))}
      </div>
    </div>
  );
};

export default EventsCalendar;
