import { useEffect, useState } from "react";
// Interfaces
import { Vivre, WebsiteImage } from "franco-interfaces";
//Hooks
import useDb from "../hooks/useDb";
import useFetchPromotedVivres from "../hooks/useFetchPromotedVivres";
//Geolocation
import { Geolocation } from "@capacitor/geolocation";
//GeoHashing
import { geo, db } from "../firebase/firebase";
//Components
import Map from "../components/map";
import CompanyList from "./CompanyList";
import CarouselImages from "../components/Carousel";
import loadingGif from "../assets/loading.svg";

interface Location {
  lat: number;
  lng: number;
}

const VivreSignUp: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [firstRenderLoading, setFirstRenderLoading] = useState(true);

  const { promotedVivres } = useFetchPromotedVivres(setLoading);
  //Google Maps
  const [geolocation, setGeolocation] = useState<Location>();
  //DB
  const getAllVivresWithoutAddress = useDb<Vivre>("Vivre", null).getAll;
  //States
  const [allVivres, setAllVivres] = useState<Vivre[]>([]);
  const [vivresInBoundsOfMap, setVivresInBoundsOfMap] = useState<Vivre[]>([]);
  const [queriedVivresOnMap, setQueriedVivresOnMap] = useState<Vivre[]>([]);
  const [vivresWithoutAddress, setVivresWithoutAddress] = useState<Vivre[]>([]);
  const [vivresToDisplay, setVivresToDisplay] = useState<Vivre[]>([]);
  const [vivresInList, setVivresInList] = useState<Vivre[]>([]);
  const [filter, setFilter] = useState("Afficher");
  const [searchString, setSearchString] = useState("");
  const [queryResults, setQueryResults] = useState<Vivre[]>([]);
  const [vivresLength, setVivresLength] = useState(0);
  const [promoted, setPromoted] = useState<any>([]);
  const [images, setImages] = useState<WebsiteImage[]>([]);
  const getAllImages = useDb<WebsiteImage>("WebsiteImages", null).getAll;

  const [bounds, setBounds] = useState<{
    northEastLat: number;
    northEastLng: number;
    southWestLat: number;
    southWestLng: number;
  }>();

  //Fetch Vivres without address to calculate total number of vivres.  Needed for number of hits on Algolia
  const fetchVivresWithoutAddress = async (): Promise<Vivre[]> => {
    const vivresWithoutAddress = await getAllVivresWithoutAddress({
      filters: [
        {
          field: "address",
          operator: "==",
          value: {},
        },
        {
          field: "status",
          operator: "==",
          value: "Approuvé",
        },
      ],
    });
    return vivresWithoutAddress;
  };

  const fetchImages = async () => {
    const fetchedImages = await getAllImages({
      orderBy: [{ field: "createdAt", direction: "desc" }],
    });
    setImages(fetchedImages);
    setFirstRenderLoading(false);
  };

  useEffect(() => {
    promotedVivres.forEach((vivre) => {
      setPromoted((prevState: any) => [...prevState, vivre.id]);
    });
  }, [promotedVivres]);

  //Fetch and set GEOLOCATION
  useEffect(() => {
    const getLocation = async () => {
      try {
        const coordinates = await Geolocation.getCurrentPosition();
        setGeolocation({
          lat: coordinates.coords.latitude,
          lng: coordinates.coords.longitude,
        });
      } catch (e) {
        setGeolocation({ lat: 55, lng: -115 });
      }
    };
    getLocation();
  }, []);

  useEffect(() => {
    fetchAndOrderVivresByDistance();
  }, [geolocation]);

  const fetchAndOrderVivresByDistance = async () => {
    if (!geolocation) return;
    const element = document.getElementById("map");
    const metersPerPx =
      (156543.03392 * Math.cos((geolocation.lat * Math.PI) / 180)) /
      Math.pow(2, 1);
    const radiusInKm =
      (metersPerPx * (element ? element.clientWidth : 0)) / (2 * 1000);
    const center = geo.point(geolocation.lat, geolocation.lng); //where user is
    const query = db.collection("Vivre").where("status", "==", "Approuvé");
    const results = geo.query(query).within(center, radiusInKm, "geoHash");
    const ids: string[] = [];
    let vivres: any[] = [];
    results.forEach(async (result: unknown) => {
      //sets a temporary state of all the Vivres located within the view of your map on the app.
      vivres = [...(result as any)];
      vivres.forEach((vivre) => {
        const id = (vivre as Vivre).id;
        if (id) {
          ids.push(id);
        }
      });
      const setOfIds = new Set(ids);
      const setOfVivres = vivres.filter((vivre) => {
        if (vivre?.id && setOfIds.has(vivre.id)) {
          setOfIds.delete(vivre.id);
          return vivre;
        }
      });
      setVivresWithoutAddress(await fetchVivresWithoutAddress());
      setAllVivres(setOfVivres as Vivre[]);
      setVivresLength(setOfVivres.length); //?? NOT SURE IF I STILL NEED THIS NOW
      fetchImages();
    });
  };

  //If map is moved then this useffect will run to update list to correspond with vivres that are visible on map
  useEffect(() => {
    if (!bounds) return;
    const vivresWithinBounds = allVivres.filter((vivre) => {
      const lat = vivre.geoHash ? vivre.geoHash?.geopoint._lat : 0;
      const lng = vivre.geoHash ? vivre.geoHash?.geopoint._long : 0;
      let isLongInRange = false;
      let isLatInRange = false;
      if (
        bounds?.southWestLng < bounds?.northEastLng &&
        bounds?.southWestLat < bounds?.northEastLat
      ) {
        isLongInRange =
          lng >= bounds?.southWestLng && lng <= bounds?.northEastLng;
        isLatInRange =
          lat >= bounds?.southWestLat && lat <= bounds?.northEastLat;
        if (isLongInRange && isLatInRange) return true;
      }
    });
    setVivresInBoundsOfMap(vivresWithinBounds);
    if (loading) return;
    if (searchString) {
      const queriedVivresOnMap = findQueryResultsInBounds(queryResults);
      const filteredVivres = filterVivres(queriedVivresOnMap);
      setVivresToDisplay(filteredVivres);
      setVivresInList(filterVivres(queryResults));
    } else {
      const filteredVivres = filterVivres(vivresWithinBounds);
      setVivresToDisplay(filteredVivres);
      setVivresInList([
        ...filterVivres([...filteredVivres, ...vivresWithoutAddress]),
      ]);
    }
  }, [bounds, allVivres, loading]);

  const filterVivres = (vivresToFilter: Vivre[]): Vivre[] => {
    let filteredVivres: Vivre[] = [];
    if (loading) return filteredVivres;
    if (filter === "Tout" || filter === "Afficher") {
      const promotedVivresId = promotedVivres.map((vivre) => {
        return vivre.id;
      });
      return [
        ...promotedVivres,
        ...vivresToFilter.filter(
          (vivre) => !promotedVivresId.includes(vivre.id)
        ),
      ];
    } else {
      filteredVivres = vivresToFilter.filter((vivre) =>
        vivre.category.includes(filter)
      );
    }
    return filteredVivres;
  };

  /*When we use the search bar, because algolia cannot filter based on location,
  we must compare our queried results to the state "vivresWithinBounds", which 
  contains ALL vivres regardless of filters or searched values, that are currently 
  within the bounds of the map*/
  const findQueryResultsInBounds = (queriesToBound: Vivre[]): Vivre[] => {
    const queryResultsIds = queriesToBound.map((vivre) => {
      return vivre.id;
    });
    const queryResultsInBounds = vivresInBoundsOfMap.filter((vivre) => {
      if (queryResultsIds.includes(vivre.id)) {
        return true;
      }
    });
    setQueriedVivresOnMap(queryResultsInBounds); //this state keeps track of queried vivres within bounds
    return queryResultsInBounds;
  };

  useEffect(() => {
    if (loading) return;
    if (searchString) {
      setVivresToDisplay(filterVivres(queriedVivresOnMap));
      const vivresWithoutAddressIds = vivresWithoutAddress.map(
        (vivre) => vivre.id
      );
      const queriedVivreWithoutAddress = queryResults.filter((result) =>
        vivresWithoutAddressIds.includes(result.id)
      );
      setVivresInList(
        filterVivres([...queriedVivresOnMap, ...queriedVivreWithoutAddress])
      );
    } else {
      setVivresToDisplay(filterVivres(vivresInBoundsOfMap));
      setVivresInList(
        filterVivres([...vivresInBoundsOfMap, ...vivresWithoutAddress])
      );
    }
  }, [filter]);

  useEffect(() => {
    if (loading) return;
    const queriedVivresOnMap = findQueryResultsInBounds(queryResults);
    setVivresToDisplay(filterVivres(queriedVivresOnMap));
    setVivresInList(
      filterVivres(
        queryResults.filter((query) => {
          if (query.id !== "PromotedVivres") return query;
        })
      )
    );
  }, [queryResults]);

  return (
    <div>
      {firstRenderLoading ? (
        <>
          <div className="loading">
            <img src={loadingGif} />
          </div>
          {geolocation && (
            <Map
              id="map"
              currentLocation={geolocation}
              businessLocations={vivresToDisplay.map((vivre) => ({
                geoPoint: vivre!.geoHash!.geopoint,
                name: vivre.name,
                logo: vivre.logo,
                status: vivre.status,
                busid: vivre.id,
              }))}
              fetchBounds={setBounds}
            />
          )}
        </>
      ) : (
        <>
          <div className="carousel">
            <CarouselImages images={images} />
          </div>

          <div className="vivreSignUp">
            <CompanyList
              algoliaSearchString={searchString}
              setAlgoliaSearchString={setSearchString}
              setAlgoliaQueryResults={setQueryResults}
              filteredVivres={vivresInList}
              filter={filter}
              setFilter={setFilter}
              hitsPerPage={vivresLength}
              promoted={promoted}
            />
            {geolocation && (
              <Map
                id="map"
                currentLocation={geolocation}
                businessLocations={vivresToDisplay.map((vivre) => ({
                  geoPoint: vivre!.geoHash!.geopoint,
                  name: vivre.name,
                  logo: vivre.logo,
                  status: vivre.status,
                  busid: vivre.id,
                }))}
                fetchBounds={setBounds}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default VivreSignUp;
