import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useContext,
} from "react";
import { Context } from "../appContext";

import { useHistory } from "react-router-dom";

//Custom Components
import AlgoliaSearchBar from "../components/AlgoliaSearchBar";

//assets
import free from "../assets/free.svg";
import inPerson from "../assets/inPerson.svg";
import notFree from "../assets/dollarSignIcon.svg";
import virtual from "../assets/virtual.svg";

//icons
import { BiChevronRight } from "react-icons/bi";

//interfaces
import {
  Event as EventInterface,
  Tag as TagInterface,
} from "franco-interfaces";

//MUI
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

//db
import useDb from "../hooks/useDb";

//Geolocation
import { Geolocation } from "@capacitor/geolocation";

// Firebase
import { Filter } from "../firebase/requests";

//utils
import { distanceInKmBetweenEarthCoordinates } from "../utils/math";
import moment from "moment";

interface Props {
  events: EventInterface[];
  setEvents: Dispatch<SetStateAction<EventInterface[]>>;
}

interface Location {
  lat: number;
  lng: number;
}

const EventsList: React.FC<Props> = ({ events, setEvents }) => {
  const [searchString, setSearchString] = useState("");
  const [queryResults, setQueryResults] = useState<EventInterface[]>([]);
  const [geolocation, setGeolocation] = useState<Location>();

  const getAllEvents = useDb<EventInterface>("Events", null).getAll;

  //filtering states
  const [chosenTagFilter, setChosenTagFilter] = useState<string>("Toutes");
  const [proximityText, setProximityText] = useState<string>("Partout");
  const [proximityApplied, setProximityApplied] = useState<boolean>(false);
  const [dateFilterApplied, setDateFilterApplied] = useState<string>("Aucun");

  const hist = useHistory();

  //FILTERING FUNCTIONS

  const getAllEventsBasicFiltering = async () => {
    const allEvents: EventInterface[] = await getAllEvents({
      orderBy: [{ field: "dates.releaseDate", direction: "asc" }],
      filters: [
        {
          field: "dates.releaseDate",
          operator: "<=",
          value: new Date(),
        },
        {
          field: "online",
          operator: "==",
          value: true,
        },
        {
          field: "cancelled",
          operator: "==",
          value: false,
        },
        {
          field: "finished",
          operator: "==",
          value: false,
        },
      ],
    });

    const test = allEvents.sort((a: any, b: any) => {
      return a.dates.startDate.seconds - b.dates.startDate.seconds;
    });
    return test;
  };

  const simpleSortFunction = (list: EventInterface[]): EventInterface[] => {
    return list.sort((a: any, b: any) => {
      return a.dates.startDate - b.dates.startDate;
    });
  };

  const fetchGeolocation = async () => {
    const coordinates = await Geolocation.getCurrentPosition();
    setGeolocation({
      lat: coordinates.coords.latitude,
      lng: coordinates.coords.longitude,
    });
  };

  const getEventsByDate = (value: number) => {
    const today = new Date();
    const date = today.setTime(today.getTime() + value);
    return date;
  };

  const weekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
  const monthInMilliseconds = 31 * 24 * 60 * 60 * 1000;

  const week = getEventsByDate(weekInMilliseconds);
  const twoWeeks = week + weekInMilliseconds;
  const month = getEventsByDate(monthInMilliseconds);

  const sortEventsByDistance = (
    eventsToSort: EventInterface[]
  ): EventInterface[] => {
    const eventsWithDistanceField = eventsToSort.map(
      (event: EventInterface) => {
        if (event.location.inPerson && geolocation) {
          const distance = distanceInKmBetweenEarthCoordinates(
            geolocation.lat,
            geolocation.lng,
            event.location.geoHash!.geopoint._lat,
            event.location.geoHash!.geopoint._long
          );
          return { ...event, distance: distance };
        } else {
          return { ...event };
        }
      }
    );

    const filteredEvents = eventsWithDistanceField.filter((item: any) => {
      return item.distance;
    });

    const sortedEvents = simpleSortFunction(filteredEvents);

    return sortedEvents;
  };

  const handleChosenEvents = async (value: any) => {
    let fetchedEventsByTag: EventInterface[] = await getAllEvents({
      orderBy: [{ field: "dates.releaseDate", direction: "asc" }],
      filters: [
        {
          field: "dates.releaseDate",
          operator: "<=",
          value: new Date(),
        },
        {
          field: "tags",
          operator: "array-contains",
          value: value ? value : chosenTagFilter,
        } as Filter,
        {
          field: "online",
          operator: "==",
          value: true,
        },
        {
          field: "cancelled",
          operator: "==",
          value: false,
        },
        {
          field: "finished",
          operator: "==",
          value: false,
        },
      ],
    });
    if (dateFilterApplied === "Semaine") {
      const newArray = fetchedEventsByTag.filter((item: any) => {
        if (
          item.dates.endDate.seconds * 1000 >= week &&
          item.dates.endDate.seconds * 1000 <= twoWeeks
        ) {
          return item;
        }
      });
      fetchedEventsByTag = newArray;
    }
    if (dateFilterApplied === "Mois") {
      const newArray = fetchedEventsByTag.filter((item: any) => {
        if (item.dates.endDate.seconds * 1000 >= month) {
          return item;
        }
      });
      fetchedEventsByTag = newArray;
    }
    if (proximityApplied) {
      fetchedEventsByTag = sortEventsByDistance(fetchedEventsByTag);
    }
    if (fetchedEventsByTag.length > 0) {
      setEvents((prevState) =>
        value !== undefined
          ? [...fetchedEventsByTag]
          : [...prevState, ...fetchedEventsByTag]
      );
    }
    if (fetchedEventsByTag.length === 0) setEvents([]);
    // if (value === "Toutes" && !proximityApplied) {
    //   const allEvents = await getAllEventsBasicFiltering();
    //   const sortedAllEvents = allEvents.sort((a: any, b: any) => {
    //     return b.dates.startDate - a.dates.startDate;
    //   });
    //   setEvents([...sortedAllEvents]);
    // }
    return fetchedEventsByTag;
  };

  const handleChosenProximity = async (value: any) => {
    if (value === "Proximité" && chosenTagFilter === "Toutes") {
      const fetchEvents = async () => {
        let allEvents = await getAllEventsBasicFiltering();
        if (dateFilterApplied === "Semaine") {
          const newArray = allEvents.filter((item: any) => {
            if (
              item.dates.endDate.seconds * 1000 >= week &&
              item.dates.endDate.seconds * 1000 <= twoWeeks
            ) {
              return item;
            }
          });
          allEvents = newArray;
        }
        if (dateFilterApplied === "Mois") {
          const newArray = allEvents.filter((item: any) => {
            if (item.dates.endDate.seconds * 1000 >= month) {
              return item;
            }
          });
          allEvents = newArray;
        }

        setEvents(sortEventsByDistance(allEvents));
      };
      fetchEvents();
    } else if (value === "Partout") {
      if (chosenTagFilter !== "Toutes") {
        handleChosenEvents(chosenTagFilter);
      } else {
        const allEvents = await getAllEventsBasicFiltering();

        setEvents([...allEvents]);
      }
    }
  };

  const handleChosenDate = async (value: any) => {
    if (value === "Semaine") {
      const fetchEvents = async () => {
        const allEvents = await getAllEventsBasicFiltering();
        const filteredEvents = allEvents.filter((item: any) => {
          if (
            item.dates.endDate.seconds * 1000 >= week &&
            item.dates.endDate.seconds * 1000 <= twoWeeks
          ) {
            return item;
          }
        });
        if (proximityApplied && chosenTagFilter !== "Toutes") {
          handleChosenEvents(chosenTagFilter);
        }
        if (proximityApplied && chosenTagFilter === "Toutes")
          setEvents(sortEventsByDistance(filteredEvents));
        if (!proximityApplied && chosenTagFilter !== "Toutes")
          setEvents([...filteredEvents]);
      };
      fetchEvents();
    }
    if (value === "Mois") {
      const fetchEvents = async () => {
        const allEvents = await getAllEventsBasicFiltering();
        const filteredEvents = allEvents.filter((item: any) => {
          if (item.dates.endDate.seconds * 1000 >= month) {
            return true;
          }
        });
        if (proximityApplied) setEvents(sortEventsByDistance(filteredEvents));
        else {
          setEvents(filteredEvents);
        }
      };
      fetchEvents();
    }
    if (value === "Aucun") {
      if (chosenTagFilter !== "Toutes" && proximityApplied) {
        handleChosenEvents(chosenTagFilter);
      }
      if (chosenTagFilter === "Toutes" && proximityApplied) {
        handleChosenProximity("Proximité");
      }

      if (chosenTagFilter === "Toutes" && !proximityApplied) {
        const allEvents = await getAllEventsBasicFiltering();
        const sortedAllEvents = allEvents.sort((a: any, b: any) => {
          return b.dates.startDate - a.dates.startDate;
        });
        setEvents(sortedAllEvents);
      }
    }
  };

  // moment language
  moment.locale("fr");

  //USEEFFECTS
  useEffect(() => {
    const sortedEvents = queryResults.sort((a: any, b: any) => {
      return a.dates.startDate - b.dates.startDate;
    });
    setEvents(sortedEvents);
  }, [queryResults]);

  useEffect(() => {
    fetchGeolocation();
  }, []);

  //FILTERING AS WELL, sometimes when you call a function, the state was not set on time for a specific if statement to fire, so you add
  //these useEffects to counter that issue

  useEffect(() => {
    if (
      chosenTagFilter === "Toutes" &&
      !proximityApplied &&
      dateFilterApplied === "Aucun"
    ) {
      getAllEventsBasicFiltering().then((events) => {
        setEvents([...events]);
      });
    }
    if (proximityApplied && chosenTagFilter === "Toutes") {
      handleChosenProximity("Proximité");
      return;
    }
    if (!proximityApplied && chosenTagFilter === "Toutes") {
      setEvents([]);
    }
    if (proximityApplied && dateFilterApplied !== "Aucun") {
      handleChosenEvents(chosenTagFilter);
    }
  }, [chosenTagFilter]);

  useEffect(() => {
    if (proximityApplied && chosenTagFilter !== "Toutes") {
      handleChosenEvents(chosenTagFilter);
    }

    if (!proximityApplied && chosenTagFilter !== "Toutes") {
      handleChosenEvents(chosenTagFilter);
    }
    if (dateFilterApplied !== "Aucun" && chosenTagFilter === "Toutes") {
      handleChosenProximity("Proximité");
    }
  }, [proximityApplied]);

  useEffect(() => {
    if (
      chosenTagFilter !== "Toutes" &&
      !proximityApplied &&
      dateFilterApplied !== "Aucun"
    )
      return;
    if (proximityApplied && chosenTagFilter !== "Toutes") {
      handleChosenDate(dateFilterApplied);
    }
    if (chosenTagFilter !== "Toutes" && !proximityApplied) {
      handleChosenEvents(chosenTagFilter);
    }
  }, [dateFilterApplied]);

  const tags:string[] = ["Arts de la scène (théâtre, danse, musique et humour)", "Arts visuels", "Cinéma et télé", "Commerce et entreprenariat", "Gastronomie", "Histoire", "Jeunesse", "Justice", "Littérature", "Musique et festivals", "Politique", "Santé", "Science et environnement", "Sports et loisirs", "Vie communautaire"]

  return (
    <div className="eventList__container">
      <AlgoliaSearchBar
        collection="Events"
        searchString={searchString}
        setSearchString={setSearchString}
        setQueryResults={setQueryResults}
        hitsPerPage={1000}
      />
      <h6>Filter par:</h6>
      <span>Thématique</span>
      <Select
        sx={{ width: "100%" }}
        onChange={(e) => {
          handleChosenEvents(e.target.value);
          setChosenTagFilter(e.target.value);
        }}
        label="Toutes"
        value={chosenTagFilter}
      >
        <MenuItem value="Toutes" key={"Toutes"}>
          Toutes
        </MenuItem>
        {tags.map((tag, i) => (
          <MenuItem value={tag} key={i}>
            {tag}
          </MenuItem>
        ))}
      </Select>
      <span>Localisation</span>
      <Select
        sx={{ width: "100%" }}
        onChange={(e) => {
          handleChosenProximity(e.target.value);
          setProximityApplied(!proximityApplied);
          setProximityText(e.target.value);
        }}
        label="Partout"
        value={proximityText}
      >
        <MenuItem key={1} value={"Partout"}>
          Partout
        </MenuItem>
        <MenuItem key={2} value="Proximité">
          Proximité
        </MenuItem>
      </Select>
      <span>Date</span>
      <Select
        sx={{ width: "100%" }}
        onChange={(e) => {
          handleChosenDate(e.target.value);
          setDateFilterApplied(e.target.value);
        }}
        label="Toutes"
        value={dateFilterApplied}
      >
        <MenuItem key={1} value={"Semaine"}>
          Cette semaine
        </MenuItem>
        <MenuItem key={2} value="Mois">
          Ce mois-ci
        </MenuItem>
        <MenuItem key={3} value={"Aucun"}>
          Toutes
        </MenuItem>
      </Select>
      <div className="events__container">
        {events.map((e: any, i: number) => (
          <article
            className={
              Date.now() > e.dates.endDate.seconds * 1000
                ? "card events__finished"
                : "card"
            }
            key={i}
          >
            <img
              src={e.imageUrl[0]}
              className="card__header"
              onClick={() => {
                hist.push(`/calendrier/${e.id}`, e);
              }}
            ></img>
            <p
              className="card__header__tag"
            >
              {e.tags[0]}
            </p>
            <div className="card__body">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <p className="card__startDate">
                  {moment(new Date(e.dates.startDate.seconds * 1000)).format(
                    "DD/MM/YYYY"
                  )}
                </p>
                <div
              style={{
                display: "flex",
                gap: "10px",
                height: "30px",
              }}
              className="event__tooltip"
            >
              {e?.cost.payForEntry ? (
                <div className="event__tooltip--dollar">
                  <img
                    src={notFree}
                    alt=""
                    style={{ height: "30px", width: "30px" }}
                  />
                </div>
              ) : (
                <div className="event__tooltip--free">
                  <img
                    src={free}
                    alt=""
                    style={{ height: "30px", width: "30px" }}
                  />
                </div>
              )}
              {e?.location.inPerson ? (
                <div className="event__tooltip--presentiel">
                  <img
                    src={inPerson}
                    alt=""
                    style={{ height: "30px", width: "30px" }}
                  />
                </div>
              ) : (
                <div className="event__tooltip--virtual">
                  <img
                    src={virtual}
                    alt=""
                    style={{ height: "30px", width: "30px" }}
                  />
                </div>
              )}
            </div>
              </div>
              <h4 className="card__title">{e.title}</h4>
              <p
                style={{
                  paddingBottom: "40px",
                  fontSize: "13px",
                  lineHeight: "1.8",
                }}
              >
                {e.shortDescription}
              </p>
              <button
                onClick={() => {
                  hist.push(`/calendrier/${e.id}`, e);
                  history.go(0);
                }}
                className="card__button"
              >
                <BiChevronRight /> Plus d'info
              </button>
            </div>
          </article>
        ))}
      </div>
    </div>
  );
};

export default EventsList;
