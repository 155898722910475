import React from "react";
import Typography from "@material-ui/core/Typography";

interface Props {
    title: string;
  }

const Title: React.FC<Props> = ({ title }) => {
  return (
    <Typography
      component="h2"
      variant="h5"
      gutterBottom
      style={{ paddingBottom: 20, color: "#8a8a8a" }}
    >
      {title}
    </Typography>
  );
};

export default Title;
