import React, { useEffect, useState } from "react";

//Components
import EventsList from "../components/EventsList";
import EventsCalendar from "../components/EventsCalendar";

//MUI
import Switch from "@mui/material/Switch";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

//Interfaces
import { Event as EventInterface } from "franco-interfaces";
import useDb from "../hooks/useDb";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const Events: React.FC = () => {
  const [calendarView, setCalendarView] = useState<boolean>(false);
  const [events, setEvents] = useState<EventInterface[]>([]);
  const [allEvents, setAllEvents] = useState<EventInterface[]>([]);
  const [value, setValue] = React.useState(0);

  const getAllEvents = useDb<EventInterface>("Events", null).getAll;

  // Tabs
  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  useEffect(() => {
    const fetchEvents = async () => {
      const events: EventInterface[] = await getAllEvents({
        orderBy: [{ field: "dates.releaseDate", direction: "asc" }],
        filters: [
          {
            field: "dates.releaseDate",
            operator: "<=",
            value: new Date(),
          },
          {
            field: "online",
            operator: "==",
            value: true,
          },
          {
            field: "cancelled",
            operator: "==",
            value: false,
          },
          {
            field: "finished",
            operator: "==",
            value: false,
          },
        ],
      });
      const allEvents: EventInterface[] = await getAllEvents({
        orderBy: [{ field: "dates.releaseDate", direction: "asc" }],
        filters: [
          {
            field: "dates.releaseDate",
            operator: "<=",
            value: new Date(),
          },
          {
            field: "online",
            operator: "==",
            value: true,
          },
          {
            field: "cancelled",
            operator: "==",
            value: false,
          },
        ],
      });

      const sortedEvents = events.sort((a: any, b: any) => {
        return a.dates.startDate.seconds - b.dates.startDate.seconds;
      });

      const sortedAllEvents = allEvents.sort((a: any, b: any) => {
        return b.dates.startDate - a.dates.startDate;
      });

      setAllEvents([...sortedAllEvents]);
      setEvents([...sortedEvents]);
    };
    fetchEvents();
  }, [calendarView]);

  return (
    <>
      <div className="events__background">
        <h4 className="events__title">Événements</h4>

        <Box sx={{ width: "100%" }} className="events__tabContainer">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="list-calendar-view"
            >
              <Tab
                className="events__tabLabel"
                label="Liste"
                {...a11yProps(0)}
              />
              <Tab
                className="events__tabLabel"
                label="Calendrier"
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
        </Box>

        {value === 0 && <EventsList events={events} setEvents={setEvents} />}
        {value === 1 && <EventsCalendar events={allEvents} />}
      </div>
    </>
  );
};

export default Events;
