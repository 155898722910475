import { useState, useRef } from "react";
//Hooks
import { useHistory } from "react-router-dom";
//Validation
import { useSnackbar } from "notistack";
// Interfaces
import { User, Vivre } from "franco-interfaces";
// Custom components
import Login from "../components/Login";
import CompanyInfo from "../components/CompanyInfo";
import Checkout from "../components/Checkout";
// Mui
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const Form: React.FC = () => {
  const steps = ["Courriel", "Information du service", "Paiement"];
  // Hooks
  const history = useHistory();
  // Validation
  const { enqueueSnackbar } = useSnackbar();
  // States
  const [activeStep, setActiveStep] = useState(0);
  const [vivre, setVivre] = useState<Vivre>(defaultValues);
  const [loading, setLoading] = useState(false);
  // Ref
  const loginRef = useRef<any>(null);
  const companyInfoRef = useRef<any>(null);
  const checkoutRef = useRef<any>(null);
  // Login
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [customer, setCustomer] = useState<User | undefined>();

  const handleNext = async () => {
    setLoading(true);
    switch (activeStep) {
      case 0: {
        const moveToNextStep = await loginRef.current.handleSubmit();
        if (moveToNextStep)
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      }
      case 1: {
        await companyInfoRef.current.handleSubmit();
        const moveToNextStep = companyInfoRef.current.isSubmitSuccessful;
        if (moveToNextStep) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          enqueueSnackbar("Une erreur s'est produite", {
            variant: "error",
          });
        }
        break;
      }
      case 2: {
        await checkoutRef.current.handleSubmit();
        const moveToNextStep = checkoutRef.current.isSubmitSuccessful;
        if (moveToNextStep) {
          enqueueSnackbar("Votre application a été soumise", {
            variant: "success",
          });
          history.replace("/");
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          enqueueSnackbar("Une erreur s'est produite", {
            variant: "error",
          });
        }
        break;
      }
    }
    setLoading(false);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      history.push("/");
      return;
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (
    <div className="form">
      <form className="form__container">
        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel className="form__step" {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <br />
        {activeStep === 0 && (
          <Login
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            setUser={setCustomer}
            ref={loginRef}
          />
        )}
        {activeStep === 1 && (
          <CompanyInfo
            companyInfo={vivre}
            setCompanyInfo={setVivre}
            ref={companyInfoRef}
          />
        )}
        {activeStep === 2 && (
          <Checkout customer={customer} product={vivre} ref={checkoutRef} />
        )}
        {activeStep === 3 ? (
          <h1>Inscrire</h1>
        ) : (
          <>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                className="form__button--back "
                variant="outlined"
                color="inherit"
                disabled={loading}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Retour
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                className="form__button--next "
                variant="contained"
                color="inherit"
                disabled={loading}
                onClick={handleNext}
              >
                {activeStep === steps.length - 1
                  ? "S'abonner"
                  : activeStep < steps.length && "Suivant"}
              </Button>
            </Box>
          </>
        )}
      </form>
    </div>
  );
};

export default Form;

const defaultValues = {
  status: "",
  name: "",
  imageUrl: [""],
  logo: [""],
  french: "",
  frenchExplanation: "",
  description: "",
  email: "",
  phone: "",
  category: "",
  physical: false,
  existingHours: false,
  random: 0,
  subscriptionId: "",
  // Websites
  website: "",
  facebook: "",
  twitter: "",
  instagram: "",
  linkedIn: "",
  // Address
  address: {
    city: "",
    street: "",
    addressNumber: "",
    postalCode: "",
    country: "",
    province: "",
  },
  hours: {
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sunday: "",
  },
};
