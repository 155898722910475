import React, { useState, useEffect } from "react";
// Interfaces
import { Testimonial as TestimonialInterface } from "franco-interfaces";
//Hooks
import useDb from "../hooks/useDb";

//Custom Components
import TestimonialList from "./TestimonialList";

const Blog: React.FC = () => {
  //states
  const [testimonials, setTestimonials] = useState<TestimonialInterface[]>([]);

  //DB fetches and stuff
  const getAllTestimonials = useDb<TestimonialInterface>(
    "Testimonials",
    null
  ).getAll;

  useEffect(() => {
    const fetchTestimonials = async () => {
      const fetchedTestimonials = await getAllTestimonials({
        orderBy: [{ field: "createdAt", direction: "desc" }],
      });
      setTestimonials(fetchedTestimonials);
    };
    fetchTestimonials();
  }, []);

  return (
    <div className="vivreSignUp">
      <TestimonialList testimonials={testimonials} />
      <div className="noAdress__background"></div>
    </div>
  );
};

export default Blog;
