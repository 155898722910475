import { useEffect, useState } from "react";
import { singletonHook } from "react-singleton-hook";

// Interfaces
import { User } from "franco-interfaces";

// Firebase
import { auth } from "../firebase/firebase";

import useDb from "../hooks/useDb";

const COLLECTION = "Users";

const initState = { currentUser: null, fetchingUser: true };

const useFetchUserImpl = () => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [fetchingUser, setFetchingUser] = useState<boolean>(true);
  const { onSnapshot } = useDb<User>(COLLECTION, currentUser);

  useEffect(() => {
    auth.onAuthStateChanged(async (res) => {
      if (res) {
        setFetchingUser(true);
        onSnapshot({
          callback: (data: User) => {
            setCurrentUser(data);
            setFetchingUser(false);
          },
          id: res.uid,
        });
      } else {
        setCurrentUser(null);
        setFetchingUser(false);
      }
    });
  }, []);

  return { currentUser, fetchingUser };
};

export const useFetchUser = singletonHook(initState, useFetchUserImpl);
