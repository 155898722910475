import imageCompression from "browser-image-compression";
import { nanoid } from "nanoid";
import { storage } from "../../firebase/firebase";

/**
 * @param storagePath string    The path where the file will be stored in the firebase bucket
 *
 * @returns Promise Resolves to the url of the uploaded file. If something goes wrong, the error can be catched outside this function,
 *                  however, this can be changed since we only send a message as an error. The actual error is logged.
 * */
export const getDownloadUrls = async (
  storagePath: string,
  imgFiles: File[],
  maxSizeMB: number,
  maxWidthOrHeight: number,
  uploadCallback?: (f: File) => void
): Promise<string[]> => {
  const compressedImgs: File[] = await Promise.all(
    imgFiles.map((img: File) =>
      imageCompression(img, { maxSizeMB, maxWidthOrHeight, useWebWorker: true })
    )
  );

  return Promise.all(
    compressedImgs.map((img: File) => {
      const storageRef = storage.ref(
        `Images/${storagePath}/${nanoid()}.${img.type.split("/").pop()}`
      );
      const task = storageRef.put(img);
      return new Promise<string>((resolve, reject) => {
        task.on(
          "state_changed",
          () => {
            const c = "a";
          },
          (error: any) => {
            console.error(error);
            reject("Erreur lors de la transmission d'image");
          },
          async () => {
            const url = await task.snapshot.ref.getDownloadURL();

            if (typeof uploadCallback !== "undefined") uploadCallback(img);

            resolve(url);
          }
        );
      });
    })
  );
};

export const deleteImageFromStorage = (url: string) => {
  const start = url.indexOf("Images");
  const end = url.indexOf("?");

  const path = decodeURIComponent(url.substring(start, end));

  const imageRef = storage.ref().child(path);

  return imageRef.delete();
};
